import { React, ReactDOM } from "@common/3rd";
import App from "./app";
// import registerServiceWorker from './registerServiceWorker';

declare global {
  interface Window {
    __wxjs_environment?: string;
  }
}

// add polyfill
if (!Array.prototype.flat) {
  Array.prototype.flat = function() {
    var depth = arguments[0];
    depth = depth === undefined ? 1 : Math.floor(depth);
    if (depth < 1) return Array.prototype.slice.call(this);
    return (function flat(arr, depth) {
      var len = arr.length >>> 0;
      var flattened = [] as any[];
      var i = 0;
      while (i < len) {
        if (i in arr) {
          var el = arr[i];
          if (Array.isArray(el) && depth > 0) flattened = flattened.concat(flat(el, depth - 1));
          else flattened.push(el);
        }
        i++;
      }
      return flattened;
    })(this, depth);
  };
}

console.time("Render whole application.");
ReactDOM.render(<App/>, document.getElementById("root"), () => {
  console.log(`App start on ${process.env.REACT_APP_ENV_NAME}`);
  console.timeEnd("Render whole application.");
});

// registerServiceWorker();

import { StyledPropsTheme } from "@my-types";
import parent from "./standard";

export default Object.assign({}, parent, {
  COLOR_PRIMARY: "#e26227",
  COLOR_PRIMARY_LIGHT_O75: "rgba(226, 98, 39, 0.75)",
  COLOR_PRIMARY_LIGHT_010: "rgba(226, 98, 39, 0.1)",
  COLOR_PRIMARY_LIGHT: "#e88354",
  INPUT_FOCUS_BORDER_COLOR: "rgba(226, 98, 39, 0.8)",
  TOGGLE_TRUE_BORDER_COLOR: "#e26227",
  TOGGLE_TRUE_BACKGROUND_COLOR: "#e26227",
  'primary-color': '#e3783c',
  'text-color': '#878787',
  'link-color': '#e07739',
  'processing-color': '#d37037',
  'card-actions-background': '#f5f8fa',
  'layout-trigger-background': '#d37037',
  'layout-header-background': '#d37037',
  'menu-dark-submenu-bg': '#d37037',
  'menu-bg': '#fff',
  'item-active-bg': '#d37037',
  'item-hover-bg': '#d37037',
  'menu-item-active-bg': '#d37037',
  'menu-highlight-color': '#d37037',
  'border-color-base': '#ebebeb',
  'disabled-color': '#bbb',
  'disabled-bg': '#f8f8f8',
  'border-radius-base': '2px',
  'border-radius-sm': '2px',
  'border-width-base': '1px',
  'layout-header-height': '64px',
  'control-padding-horizontal': '16px',
  'radio-button-hover-color': '#fea805',
  'radio-button-active-color': '#fea805',
  'label-color': '#949494',
  'label-margin-horizontal': '30px',
  'form-item-trailing-colon': false,
  'input-height-base': '42px',
  'input-height-lg': '42px',
  'input-height-sm': '33px',
  'input-color': '#333',
  'font-family-no-number':
      'Raleway, "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif',
  'font-family':
      '"Open Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, @font-family-no-number',
  'code-family': 'Raleway, Consolas, Menlo, Courier, monospace',
}) as StyledPropsTheme;

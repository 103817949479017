
/**
 * storage delegate
 */
class StorageDelegate {
  private name: string;
  private storage: Storage;

  constructor(name: string, storage: Storage) {
    this.name = name;
    this.storage = storage;
  }

  set(key: string, value: any): this {
    const json = this.current();
    json[key] = value;
    this.storage.setItem(this.name, JSON.stringify(json));
    return this;
  }

  get(key: string): any {
    return this.current()[key];
  }

  remove(key: string): this {
    const current = this.current();
    delete current[key];
    this.storage.setItem(this.name, JSON.stringify(current));
    return this;
  }

  /**
   * get current storage values, returns {} if nothing
   */
  current(): any {
    const currentValue = this.storage.getItem(this.name);
    return currentValue ? JSON.parse(currentValue) : {};
  }

  clear(): this {
    this.storage.removeItem(this.name);
    return this;
  }
}

/**
 * storage proxy, to session storage and local storage
 */
class StorageProxy {
  private sessionStorage: StorageDelegate;
  private localStorage: StorageDelegate;

  constructor(name: string) {
    this.sessionStorage = new StorageDelegate(name, window.sessionStorage);
    this.localStorage = new StorageDelegate(name, window.localStorage);
  }

  session(): StorageDelegate {
    return this.sessionStorage;
  }

  local(): StorageDelegate {
    return this.localStorage;
  }

  get(key: string): any {
    return this.session().get(key) || this.local().get(key);
  }

  set(key: string, value: any): this {
    this.session().set(key, value);
    this.local().set(key, value);
    return this;
  }

  remove(key: string): this {
    this.session().remove(key);
    this.local().remove(key);
    return this;
  }

  clear(): this {
    this.session().clear();
    this.local().clear();
    return this;
  }
}

/**
 * theme storage
 */
class ThemeStorage extends StorageProxy {
  constructor() {
    super("Insmate-Theme");
  }

  set(key: string, value: any): this {
    this.session().set(key, value);
    if (process.env.REACT_APP_ENV_NAME !== "LOCAL") {
      this.local().set(key, value);
    }
    return this;
  }
}


// initialize storages
const Auth = new StorageProxy("Insmate-Auth");
const Account = new StorageProxy("Insmate-Account");
const Env = new StorageProxy("Insmate-Env");
const currentRouters = new StorageProxy("Insmate-CurrentRouters");
const TitleLogo = new StorageProxy("Insmate-TitleLogo");
const Theme = new ThemeStorage();

export default {
  Auth,
  Account,
  Env,
  Theme,
  TitleLogo,
  currentRouters,
  clear: (): void => {
    window.sessionStorage.clear();
    Auth.local().clear();
    Env.local().clear();
  }
};

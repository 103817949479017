import { React } from "@common/3rd";
import Consts from "./consts";
import Storage from "./storage";

class Language {
  message: string;
  protected _language: string = Consts.LANGUAGE_EN;
  protected _profile: any;
  protected useEn: boolean;

  constructor() {
    this.message = "";
    this.useEn = false;
    this.profile = (Storage.Account.session().get(Consts.ACCOUNT_KEY) || {}).profile;
    this.language = !this.profile ? Consts.LANGUAGE_EN : this.profile.preferredLang || Consts.LANGUAGE_EN;
  }

  refreshLanguage() {
    this.profile = (Storage.Account.session().get(Consts.ACCOUNT_KEY) || {}).profile;
    this.language = !this.profile ? Consts.LANGUAGE_EN : this.profile.preferredLang || Consts.LANGUAGE_EN;
  }

  get profile() {
    return (Storage.Account.session().get(Consts.ACCOUNT_KEY) || {}).profile;
  }

  set profile(v: any) {
    this._profile = v;
  }

  set language(language: string) {
    this._language = language;
  }

  get language(): string {
    return this._language;
  }

  en(message: string) {
    this.useEn = true;
    this.message = message;
    return this;
  }

  thai(message: string) {
    if (this.language === Consts.LANGUAGE_THAI) {
      this.message = message;
    }
    return this;
  }

  my(message: string) {
    if (this.language === Consts.LANGUAGE_MY) {
      this.message = message;
    }
    return this;
  }
  getMessage() {
    if (!this.useEn) {
      console.error("You must use Language.en first.");
      if (this.language === Consts.LANGUAGE_EN) return "";
    }
    const message = this.message;
    this.useEn = false;
    return message;
  }
}

const HungrySingleton: any = (function() {
  const _instance = new Language();

  return function() {
    return _instance;
  };
})();

const LanguageInstance = new HungrySingleton();

export default LanguageInstance;

import { StyledPropsTheme } from '@my-types';

import * as styledComponents from 'styled-components';

const {
	default: styled,
	css,
	createGlobalStyle,
	keyframes,
	ThemeProvider,
	ThemeContext,
	ThemeConsumer
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
	StyledPropsTheme
>;

const { isStyledComponent, withTheme } = styledComponents;

export {
	css,
	createGlobalStyle,
	keyframes,
	ThemeProvider,
	ThemeContext,
	ThemeConsumer
};
export { isStyledComponent, withTheme };
export default styled;

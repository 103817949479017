import { StyledPropsTheme } from "@my-types";
import parent from "./standard";

export default Object.assign({}, parent, {
  COLOR_PRIMARY: "#b33a48",
  BORDER_COLOR: '#bf5c64',
  BACKGROUND_COLOR: '#b33a48',
  COLOR_BACKGROUND_BTN: '#b33a48',
  HEADER_COLOR: '#c71d31',
  HIGHLIGHT_COLOR: '#922531',
  COLOR_A: '#bf5c64',
  HREF_A: '#b33a48',

  COLOR_PRIMARY_LIGHT_O75: "rgba(194, 32, 0, 0.75)",
  COLOR_PRIMARY_LIGHT_010: "rgba(194, 32, 0, 0.1)",
  COLOR_PRIMARY_LIGHT: "#f52800",
  INPUT_FOCUS_BORDER_COLOR: "rgba(194, 32, 0, 0.8)",
  TOGGLE_TRUE_BORDER_COLOR: "#c22000",
  TOGGLE_TRUE_BACKGROUND_COLOR: "#c22000"
}) as StyledPropsTheme;

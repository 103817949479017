import uuidv4 from "uuid/v4";
import { Envs } from "@common";

interface IApiBefore {
  "x-insmate-client-os": string;
  "x-insmate-client-type": string;
  "x-insmate-client-browser": string;
  "x-insmate-traceid": any;  // 请求唯一标识
}

export function getClientInfo(isfile?: boolean): IApiBefore {
  let os;
  if (Envs.os.macos) os = "MACOS";
  else if (Envs.os.windows) os = "WIND";
  else if (Envs.os.linux) os = "LINUX";
  else if (Envs.os.unix) os = "UNIX";
  else if (Envs.os.ios) os = "IOS";
  else if (Envs.os.android) os = "ANDROID";
  else os = "OTH";

  let browser;
  if (Envs.browser.chrome) browser = "CHROME";
  else if (Envs.browser.safari) browser = "SAFARI";
  else if (Envs.browser.opera) browser = "OPERA";
  else if (Envs.browser.firefox) browser = "FIREFOX";
  else if (Envs.browser.edge) browser = "MSEDGE";
  else if (Envs.browser.ie) browser = "IE";
  else browser = "OTH";

  let type;
  if (isfile) type = "FUP";
  else if (Envs.os.phone) type = "MBR";
  else if (Envs.os.desk) type = "PCBR";
  else if (Envs.os.phone) type = "MAPP"; // todo
  else type = "OTH";


  const result: IApiBefore = {
      "x-insmate-client-os": os,
      "x-insmate-client-type": type,
      "x-insmate-client-browser": browser,
      "x-insmate-traceid": uuidv4().split("-").join(""),
    }
  ;

  return result;
}

import { Application } from "@my-types";
import Consts from "./consts";
import Storage from "./storage";
import Utils from "./utils";

type OS = {
  android?: boolean;
  ios?: boolean;
  iphone?: boolean;
  ipad?: boolean;
  ipod?: boolean;
  wp?: boolean;
  webos?: boolean;
  touchpad?: boolean;
  blackberry?: boolean;
  bb10?: boolean;
  rimtabletos?: boolean;
  kindle?: boolean;
  firefoxos?: boolean;
  version?: string;
  tablet?: boolean;
  phone?: boolean;
  desk?: boolean;
  app?: boolean;
  wechat?: boolean;
  wechatMini?: boolean;
  linux?: boolean;
  unix?: boolean;
  macos?: boolean;
  windows?: boolean;
};
type BROWSER = {
  webkit?: boolean;
  playbook?: boolean;
  silk?: boolean;
  chrome?: boolean;
  firefox?: boolean;
  opera?: boolean;
  ie?: boolean;
  edge?: boolean;
  safari?: boolean;
  webview?: boolean;
  version?: string;
};

class Envs {
  private rootApp: any;
  os: OS;
  browser: BROWSER;
  standardThemeName: string = "standard";

  constructor() {
    this.os = {};
    this.browser = {};
    this.standardThemeName = "standard";
    this.detect();
  }

  /**
   * set application
   */
  application(app?: any): Application | null | undefined {
    if (!Utils.isUndefined(app)) {
      this.rootApp = app;
    } else {
      return this.rootApp;
    }
  }

  /**
   * get current theme name
   */
  getCurrentTheme(): string {
    const queryString = Utils.fromQueryString();
    if (queryString && queryString.theme) {
      return queryString.theme;
    } else {
      return Storage.Theme.get(Consts.THEME_KEY);
    }
  }

  /**
   * get relative path, add context prefix
   */
  getContextLocation(relativePath: string): string {
    let contextPath = null;
    let path = window.location.pathname;
    let secondIndex = path.indexOf("/", 1);
    if (secondIndex === -1) {
      // no context path
      contextPath = process.env.REACT_APP_AJAX_CLIENT_CONTEXT || "";
    } else {
      contextPath = path.substring(0, secondIndex);
    }
    if (relativePath) {
      return `${contextPath}${relativePath}`;
    } else {
      return contextPath;
    }
  }

  /**
   * detect environment(such as os, browser etc.)
   */
  private detect(): void {
    const ua = window.navigator.userAgent;
    const platform = window.navigator.platform;

    const os: OS = this.os,
      browser: BROWSER = this.browser,
      // eslint-disable-next-line
      webkit: any = ua.match(/Web[kK]it[\/]{0,1}([\d.]+)/),
      // eslint-disable-next-line
      android = ua.match(/(Android);?[\s\/]+([\d.]+)?/),
      // mac
      // eslint-disable-next-line
      osx = !!ua.match(/\(Macintosh\; Intel /) || ua.match(/(Mac)/),
      ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
      ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/),
      iphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
      // eslint-disable-next-line
      webos = ua.match(/(webOS|hpwOS)[\s\/]([\d.]+)/),
      win = /Win\d{2}|Windows/.test(platform) || ua.match(/(win)/),
      wp = ua.match(/Windows Phone ([\d.]+)/),
      touchpad = webos && ua.match(/TouchPad/),
      kindle = ua.match(/Kindle\/([\d.]+)/),
      silk = ua.match(/Silk\/([\d._]+)/),
      blackberry = ua.match(/(BlackBerry).*Version\/([\d.]+)/),
      bb10 = ua.match(/(BB10).*Version\/([\d.]+)/),
      rimtabletos = ua.match(/(RIM\sTablet\sOS)\s([\d.]+)/),
      playbook = ua.match(/PlayBook/),
      chrome = ua.match(/Chrome\/([\d.]+)/) || ua.match(/CriOS\/([\d.]+)/),
      firefox = ua.match(/Firefox\/([\d.]+)/),
      opera = ua.match(/opera.([\d.]+)/),
      firefoxos = ua.match(/\((?:Mobile|Tablet); rv:([\d.]+)\).*Firefox\/[\d.]+/),
      ie =
        ua.match(/MSIE\s([\d.]+)/) ||
        // eslint-disable-next-line
        ua.match(/Trident\/[\d](?=[^\?]+).*rv:([0-9.].)/),
      edge = ua.match(/(edge)\/([\w.]+)/),
      appleWebview = !chrome && ua.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/),
      safari = appleWebview || ua.match(/Version\/([\d.]+)([^S](Safari)|[^M]*(Mobile)[^S]*(Safari))/),
      linux = ua.match(/(Linux)/),
      // chrome os
      cros = ua.match(/(CrOS)/),
      androidWebview = ua.match(/(wv\))/);

    if ((browser.webkit = !!webkit)) {
      browser.version = webkit[1];
    }

    if (android) {
      os.android = true;
      os.version = android[2];
    }
    if (iphone && !ipod) {
      os.ios = os.iphone = true;
      os.version = iphone[2].replace(/_/g, ".");
    }
    if (ipad) {
      os.ios = os.ipad = true;
      os.version = ipad[2].replace(/_/g, ".");
    }
    if (ipod) {
      os.ios = os.ipod = true;
      os.version = ipod[3] ? ipod[3].replace(/_/g, ".") : undefined;
    }
    if (wp) {
      os.wp = true;
      os.version = wp[1];
    }
    if (webos) {
      os.webos = true;
      os.version = webos[2];
    }
    if (touchpad) {
      os.touchpad = true;
    }
    if (blackberry) {
      os.blackberry = true;
      os.version = blackberry[2];
    }
    if (bb10) {
      os.bb10 = true;
      os.version = bb10[2];
    }
    if (rimtabletos) {
      os.rimtabletos = true;
      os.version = rimtabletos[2];
    }
    if (playbook) {
      browser.playbook = true;
    }
    if (kindle) {
      os.kindle = true;
      os.version = kindle[1];
    }
    if (silk) {
      browser.silk = true;
      browser.version = silk[1];
    }
    if (!silk && os.android && ua.match(/Kindle Fire/)) {
      browser.silk = true;
    }
    if (chrome) {
      browser.chrome = true;
      browser.version = chrome[1];
    }
    if (firefox) {
      browser.firefox = true;
      browser.version = firefox[1];
    }
    if (opera) {
      browser.opera = true;
      browser.version = opera[1];
    }
    if (firefoxos) {
      os.firefoxos = true;
      os.version = firefoxos[1];
    }
    if (ie) {
      browser.ie = true;
      browser.version = ie[1];
    }
    if (edge) {
      browser.edge = true;
    }
    if (safari && (osx || os.ios || win)) {
      browser.safari = true;
      if (!os.ios) {
        browser.version = safari[1];
      }
    }

    os.tablet = !!(
      ipad ||
      playbook ||
      (android && !ua.match(/Mobile/)) ||
      (firefox && ua.match(/Tablet/)) ||
      (ie && !ua.match(/Phone/) && ua.match(/Touch/))
    );
    os.phone = !!(
      !os.tablet &&
      !os.ipod &&
      (android ||
        iphone ||
        webos ||
        blackberry ||
        bb10 ||
        (chrome && ua.match(/Android/)) ||
        (chrome && ua.match(/CriOS\/([\d.]+)/)) ||
        (firefox && ua.match(/Mobile/)) ||
        (ie && ua.match(/Touch/)))
    );
    os.desk = !!(osx || cros || win || linux) && !os.tablet && !os.phone;
    os.macos = !!(osx) && !os.tablet && !os.phone;
    os.linux = !!(linux) && !os.tablet && !os.phone;
    os.windows = !!(win) && !os.tablet && !os.phone;
    os.unix = (platform === "X11") && !os.windows && !os.macos;

    if (appleWebview || androidWebview || os.app) {
      browser.webview = true;
    }
    // is wechat
    if (/MicroMessenger/i.test(ua)) {
      os.wechat = true;
    }
    // is wechat applet
    if (window.__wxjs_environment === "miniprogram") {
      os.wechatMini = true;
    }
  }

  getAuth(): string {    
    return Storage.Auth.session().get(Consts.AUTH_KEY);
  }

  /**
   * get current login user info from session storage
   */
  findAccount(): any {
    return Storage.Account.session().get(Consts.ACCOUNT_KEY) || {};
  }

  isAuthorized(): boolean {
    return !!this.findAccount().userName;
  }

  /**
   * hold current login user info to session storage
   */

  holdAccount(account: any): void {
    Storage.Account.session().set(Consts.ACCOUNT_KEY, account);
  }
}

export default new Envs();

enum DateFormats {
  DATE_FORMAT = "DD/MM/YYYY",
  DATE_TIME_WITH_TIME_ZONE = "DD/MM/YYYYTHH:mm:ssZ",
  DATE_TIME_FORMAT_NOMAL = "/MM/YYYY HH:mm:ss",
  DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss",
  DATE_TIME_FORMAT_M = "DD/MM/YYYY HH:mm",
}

enum TenantTypes {
  AGENT = "AGENT",
  BROKER = "BROKER",
  D2C = "D2C",
  INSURER = "INSURER",
  PLATFORM = "PLATFORM",
}

enum StructTypes {
  BRH_TIM_SALES = "BRH_TIM_SALE",
  HQ = "HQ",
}

enum CurrencySymbolTable {
  SGD = "S$",
  CNY = "￥",
  RMB = "￥",
  MYR = "RM",
  THB = "฿",
}

enum OrgTypes {
  INDIVIDUAL = "INDI",
  CORPORATE = "ORG",
}

enum EndoTypes {
  INCEPTION_CANCELLATION = "INCEPTION_CANCEL",
  MIDWAY_CANCELLATION = "MIDWAY_CANCEL",
  NFN = "NFN_ENDO",
}

enum REPORT_ID {
  LAST_12M_PREMIUM = "9901",
  CURRENT_MONTH = "9902",
  PREMIUM_STRUCTURE = "9903",
  LAST_12M_PREMIUM_LIST = "9904",
  POLICIES_TODAY = "9802",
  BAHT_PREMIUM_TODAY = "9803",
  ACTIVE_AGENTS_TODAY = "9801",
  TOP5_SALES_TODAY = "9804",
  LAST_7DAY_SALES_DATA_GWP = "9805",
  LAST_7DAY_SALES_DATA_ISSUE = "9806",
}

const yesOrNo = [{ id: "Y", text: "Yes" }, { id: "N", text: "No" }];
const paymentMethod_INSTALLMENT = "INSTALLMENT";
const paymentMethod_LUMPSUM = "LUMPSUM";
const InsureTypes = {
  INDIVIDUAL: "INDI",
  CORPORATE: "ORG",
};
const lumpSumPayment = {
  value: paymentMethod_LUMPSUM,
  label: "Lump Sum",
};

const installmentPayment = {
  value: paymentMethod_INSTALLMENT,
  label: "Monthly",
};

enum QuoteStatus {
  ISSUED = "ISSUED",
  IN_PGRES = "IN_PGRES",
  BOUND = "BOUND",
  CANCELLED = "CANCELLED",
  EXP = "EXP",
  LAPSED = "LAPSED",
  UW_DECLINE = "UW_DECLINE",
  REJECTED = "REJECTED",
  CLOSED = "CLOSED",
}

enum BankAccountStatus {
  INCOMP_DD = "INCOMP_DD_BANK_ACC",
  COMP_DD = "COMP_DD_BANK_ACC",
}

enum EndoStatus {
  IN_PGRES = "IN_PGRES",
  ISSUED = "ISSUED",
  REJECTED = "REJECTED",
}

enum UwStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PGRES",
  COMPLETED = "COMPLETED",
}

enum MY_STATEMENT_STATUS {
  PENDING = "PENDING",
  PAID = "PAID",
  FAILED = "FAILED",
}

enum BANK_FILE_TYPES {
  DD_Deduct = "DD_DEDUCT",
  DD_Return = "DD_RETURN",
  BP = "BP_KNOCKOFF",
}

enum UW_BLACKLIST {
  IN_PROGRESS = "UW_BLACKLIST_IN_PROGRESS",
  APPROVAL = "UW_BLACKLIST_APPROVAL",
  DECLINE = "UW_BLACKLIST_DECLINE",
}

enum UW_CODE {
  UW_APPROVED = "APPROVED",
  UW_DECLINED = "DECLINED",
  UW_SENT_BACK = "SENT_BACK",
}

enum UW_CAR_INSPECTION {
  IN_PROGRESS = "UW_CAR_INSPECTION_IN_PROGRESS",
  COMPLETED = "UW_CAR_INSPECTION_COMPLETED",
}

enum ENTRY_METHOD {
  UPLOAD = "UP",
  ON_SCREEN = "OS",
}

export default {
  PAYMENT_METHOD_INSTALLMENT: paymentMethod_INSTALLMENT,
  PAYMENT_Method_LUMPSUM: paymentMethod_LUMPSUM,
  LUMP_SUM_PAYMENT: lumpSumPayment,
  INSTALLMENT_PAYMENT: installmentPayment,
  LANGUAGE_EN: "en",
  LANGUAGE_THAI: "th",
  LANGUAGE_MY: "my",
  FETCH_ERROR: "Network error",
  AUTH_KEY: "Authorization",
  CLIENT_COMPANY_CODE: "ClientCompanyCode",
  THEME_KEY: "name",
  ACCOUNT_KEY: "Account",
  LOGO_KEY: "Logo",
  TITLE_KEY: "title",
  FAVICON_KEY: "favicon",
  CUSTOMER_ROUTER: "customerRouter",
  MAIN_MENU_ITEM_AUTHORITIES: "mainMenuItemAuthorities",
  ADMIN_SHORTCUTS_MENU_ITEM_AUTHORITIES: "adminShortcutsMenuItemAuthorities",
  FORM_ITEM_LAYOUT: {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 13 },
    },
  },
  DATE_FORMAT: DateFormats,
  TENANT_TYPES: TenantTypes,
  STRUCT_TYPES: StructTypes,
  CURRENCY_SYMBOL_TABLE: CurrencySymbolTable,

  ORG_TYPES: OrgTypes,
  BENEFICIARY_OTHERS: "000000000",
  ID_TYPE_IC: "IC",
  YES_OR_N0: yesOrNo,
  INSURE_TYPES: InsureTypes,
  PAY_MODES_2C2P: "2C2P",
  QUOTE_STATUS: QuoteStatus,
  ENDO_STATUS: EndoStatus,
  UW_STATUS: UwStatus,
  BANK_ACCOUNT_STATUS: BankAccountStatus,
  QUOTE_STATUS_IN_PROGRESS: "IN_PGRES",
  QUOTE_STATUS_BOUND: "BOUND",
  UW_PRICE_CHECKING: "UW_PRICE_CHECKING",
  UW_PRICE_UPDATED: "UW_PRICE_UPDATED",
  MANUAL_ISSUING: "MANUAL_ISSUING",
  UW_MANUAL_REJECTED: "UW_MANUAL_REJECTED",
  UW_CAR_INSPECTION_IN_PROGRESS: "UW_CAR_INSPECTION_IN_PROGRESS",
  UW_CAR_INSPECTION_COMPLETED: "UW_CAR_INSPECTION_COMPLETED",
  UW_BLACKLIST_IN_PROGRESS: "UW_BLACKLIST_IN_PROGRESS",
  UW_BLACKLIST_APPROVAL: "UW_BLACKLIST_APPROVAL",
  UW_BLACKLIST_DECLINE: "UW_BLACKLIST_DECLINE",
  REPORT_ID: REPORT_ID,
  ENDO_TYPES: EndoTypes,
  MY_STATEMENT_STATUS: MY_STATEMENT_STATUS,
  BANK_FILE_TYPES: BANK_FILE_TYPES,
  UW_BLACKLIST: UW_BLACKLIST,
  UW_CODE: UW_CODE,
  UW_CAR_INSPECTION: UW_CAR_INSPECTION,
  ENTRY_METHOD: ENTRY_METHOD,
};

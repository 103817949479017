import { CodeItem } from "@my-types";
import Language from "@common/language";
import Consts from "@common/consts";

type CODES = {
  BOOLEAN_YN: CodeItem[];
  BIZ_TYPE: () => any[];
  UNDERWRITING_TAGS: () => any[];
  UW_DECISIONS: () => any[];
  GROUP_ENTRY_METHOD_OPTIONS: any[];
  GENDER_OPTIONS: () => any[];
};

const uwTags = () => [
  {
    label: Language.en("Premium Checking")
      .thai("ตรวจสอบราคา")
      .getMessage(),
    value: Consts.UW_PRICE_CHECKING,
  },
  {
    label: Language.en("Manual Issue")
      .thai("ตีพิมพ์ด้วยมือ")
      .getMessage(),
    value: Consts.MANUAL_ISSUING,
  },
  {
    label: Language.en("Non-financial Endorsement")
      .thai("การเปลี่ยนแปลงนโยบายที่ไม่ใช่ทางการเงิน")
      .getMessage(),
    value: "NFN_ENDO",
  },
];

export default {
  BOOLEAN_YN: [{ id: "Y", text: "Yes" }, { id: "N", text: "No" }],
  BIZ_TYPE: () => [
    {
      value: ["NB", "COMP"],
      label: Language.en("New Business")
        .thai("งานใหม่")
        .getMessage(),
    },
    {
      value: ["RN"],
      label: Language.en("Renewal")
        .thai("งานต่ออายุ")
        .getMessage(),
    },
  ],
  UNDERWRITING_TAGS: uwTags,
  UW_DECISIONS: () => [
    {
      id: "APPROVED",
      text: Language.en("Approve")
        .thai("อนุมัติ")
        .getMessage(),
    },
    {
      id: "DECLINED",
      text: Language.en("Decline")
        .thai("ปฏิเสธ")
        .getMessage(),
    },
    {
      id: "SENT_BACK",
      text: Language.en("Send Back")
        .thai("ส่งกลับ")
        .getMessage(),
    },
  ],
  GROUP_ENTRY_METHOD_OPTIONS: [
    { id: Consts.ENTRY_METHOD.ON_SCREEN, text: "On Screen" },
    { id: Consts.ENTRY_METHOD.UPLOAD, text: "Upload" },
  ],
  GENDER_OPTIONS: () => [
    {
      id: "M",
      text: Language.en("Male")
        .thai("ชาย")
        .getMessage(),
    },
    {
      id: "F",
      text: Language.en("Female")
        .thai("หญิง")
        .getMessage(),
    },
  ],
} as CODES;

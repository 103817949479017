import { Utils } from "@common/index";
import _ from "lodash";

class Rules {
  isXMultiply(num: number, multiple: number) {
    return num > 0 && num % multiple === 0;
  }

  isEmail(message: string = "Email is invalid") {
    return {
      pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
      message,
    };
  }

  isMobileNumber(message: string = "Mobile No. is invalid") {
    return {
      pattern: /^\d+$/,
      message,
    };
  }

  passwordValidate(message: string = "Password is invalid"): any {
    let rC = {
      lW: "[a-z]",//小写字母
      uW: "[A-Z]",//大写字母
      nW: "[0-9]",//汉字
      sW: "[!@#$%^&*_-]",//特殊字符
    };
    return {
      pattern: /^((lw*uw*nw) || (lw*uw*sw) || (uw*nw*sw)||(lw*nw*sw)){8,}$/,
      message,
    };
  }

  password(
    message: string = "Password must contain at least 8 chars with number, uppercase and lowercase",
  ) {
    return {
      pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
      message,
    };
  }

  isValidChassisNo(value: string): boolean {
    return new RegExp(/^[a-zA-Z0-9]{6,18}$/).test(value);
  }

  isLetterDigitalAndUnderscore(message: string) {
    return {
      pattern: /^[\w-]+$/,
      message,
    };
  }

  isValidThaiIdNo(thaiIdNo: string): boolean {
    var isValidThaiIdNo = false;
    if (thaiIdNo != null && thaiIdNo != undefined && thaiIdNo != "") {
      if (thaiIdNo.length == 13) {
        var mainDigits = [13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
        var sum = 0;
        for (var i = 0; i < 12; i++) {
          var n = Number(thaiIdNo.substring(i, i + 1));
          sum += n * mainDigits[i];
        }
        var finalResult = 11 - (sum % 11);
        if (finalResult == 10) {
          finalResult = 0;
        } else if (finalResult == 11) {
          finalResult = 1;
        }
        var lastNo = Number(thaiIdNo.substring(12));
        if (lastNo == finalResult) {
          isValidThaiIdNo = true;
        }
      }
    }
    return isValidThaiIdNo;
  }

  isValidMobileNo(mobile: string, nationCode: string = "+66") {
    if (!mobile) return true;
    var isValidMobileNo = false;
    switch (nationCode) {
      case "+66":
        if (mobile.startsWith("0")) {
          isValidMobileNo = mobile.length === 10 && /^[0-9]+$/.test(mobile);
        } else {
          isValidMobileNo = mobile.length === 9 && /^[0-9]+$/.test(mobile);
        }
        break;
      case "+65":
        isValidMobileNo =
          (mobile.startsWith("8") || mobile.startsWith("9")) &&
          mobile.length === 8 &&
          /^[0-9]+$/.test(mobile);
        break;
      case "+61":
        isValidMobileNo = mobile.length === 9 && /^[0-9]+$/.test(mobile);
        break;
      case "+86":
        isValidMobileNo = mobile.length === 11 && /^[0-9]+$/.test(mobile);
        break;
      default:
        isValidMobileNo = true;
        break;
    }
    return isValidMobileNo;
  }


  isValidaTaxNo(value: any): boolean {
    return value && !isNaN(value) && value.length == 13;
  }

  isValidVehicleNo1(value: any): boolean {
    return new RegExp(
      /^[กขฃคฅฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮ0-9\s+]{1,3}$/,
    ).test(value);
  }

  isValidVehicleNo2(value: any): boolean {
    return new RegExp(
      /^[กขฃคฅฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮ0-9\s+]{1,4}$/,
    ).test(value);
  }

  isValidAccountNo(value: any) {
    return new RegExp(/^[\d]{6,16}$/).test(value);
  }

  isFloatNumber(value: any): boolean {
    return new RegExp(/^(0|[1-9][0-9]*)(\.\d+)?$/).test(value);
  }

  // 整数
  isNumber(value: any) {
    const Reg = /^-?\d+$/;
    return Reg.test(value);
  }

  isNumberDays(value: any) {
    if (this.isNumber(value)) {
      return value > 0 && value <= 31;
    }
    return false;
  }

  isNumber0To10(value: any) {
    if (this.isNumber(value)) {
      return value >= 0 && value <= 10;
    }
    return false;
  }

  isNumberGreaterThanZero(value: any) {
    if (this.isNumber(value)) {
      return value > 0;
    }
    return false;
  }

  formatterPrice: any = (value: any) => {
    if (this.isFloatNumber(value)) {
      return Utils.toThousands(value);
    }
    return value;
  };
}

export default new Rules();

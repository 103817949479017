import Consts from "./consts";
import Envs from "./envs";

class Authority {
  private user: any;
  private tenant: any;

  constructor() {
    this.initUser();
  }

  _hasRole(role: any): boolean {
    const authorities = this.user.authorities || [];
    return authorities.some((item: any) => item.authority === role);
  }

  isChannelTenant(): boolean {
    return this.isAgent() || this.isBroker();
  }

  isChannelTenantWithD2C(): boolean {
    return this.isAgent() || this.isBroker() || this.isD2C();
  }

  isAgent(): boolean {
    return this.tenant.tenantType === Consts.TENANT_TYPES.AGENT;
  }

  isPlatform(): boolean {
    return this.tenant.tenantType === Consts.TENANT_TYPES.PLATFORM;
  }

  isInsurer(): boolean {
    return this.tenant.tenantType === Consts.TENANT_TYPES.INSURER;
  }

  isD2C(): boolean {
    return this.tenant.tenantType === Consts.TENANT_TYPES.D2C;
  }

  isBroker(): boolean {
    return this.tenant.tenantType === Consts.TENANT_TYPES.BROKER;
  }

  isSalesManager(): boolean {
    return this.isAgentManager() || this.isBrokerManager();
  }

  isNotSales(): boolean {
    return !this.isSales();
  }

  isSales(): boolean {
    return this.isBrokerSales() || this.isAgentSales();
  }

  isBrokerSales(): boolean {
    return this._hasRole("BRK_SALES");
  }

  isAgentSales(): boolean {
    return this._hasRole("AGT_SALES");
  }

  isPFAdmin(): boolean {
    return this._hasRole("PF_ADMIN");
  }

  isAgentManager(): boolean {
    return this._hasRole("AGT_MGR");
  }

  isBrokerManager(): boolean {
    return this._hasRole("BRK_MGR") || this._hasRole("BRK_BRANCH");
  }

  isPFCustomerService(): boolean {
    return this._hasRole("PF_CS");
  }

  getStructId(): boolean {
    return this.tenant.headquarter.structId || "";
  }

  getTenantCode(): boolean {
    return this.tenant.tenantCode;
  }

  getTenantId(): boolean {
    return this.tenant.tenantId;
  }

  getTenantType(): boolean {
    return this.tenant.tenantType;
  }

  initUser(): void {
    this.user = Envs.findAccount();
    this.tenant = this.user.tenant || {};
  }
}

export default new Authority();

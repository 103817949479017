/**
 * define
 */
type APIS = {
  readonly LOGIN_IN: string;
  readonly LOGO: string;
  readonly FAVICON: string;
  readonly COMPANYNAME: string;
  readonly MASTER_TABLE: string;
  readonly FAIL_MORE: string;
  readonly NAME: string;
  readonly LOAD_POLICY: string;
  readonly LOAD_COMP_QUOTE: string;
  readonly BANKS: string;
  readonly BANK_ACCOUNT: string;
  readonly OPT_EMAIL: string;
  readonly OPT_MOBILE: string;
  readonly SIGN_OUT: string;
  readonly CATEGORIES: string;
  readonly LOAD_VEHICLE_USAGE: string;
  readonly COMPARE_VMI: string;
  readonly COMPARE_CMI: string;
  readonly COMPARE_ACCIDENT: string;
  readonly COMPARE_TRAVEL: string;

  readonly COMPARE_VMI_BUY: string;
  readonly COMPARE_CMI_BUY: string;
  readonly COMPARE_PA_BUY: string;
  readonly COMPARE_TRAVEL_BUY: string;

  readonly USER_QUERY: string;
  readonly USER_SESSION_QUERY: string;
  readonly INVALIDATE_USER: string;
  readonly SYS_LOG_QUERY: string;
  readonly TASK_QUERY: string;
  readonly TASK_TYPES: string;
  readonly TENANT_QUERY: string;
  readonly TENANT: string;
  readonly PRODS_SELLING_BROKER: string,
  readonly HIERARCHY_QUERY: string;
  readonly INVALIDATE_HIERARCHY: string;
  readonly QUERY_AUDIT_LOG: string;
  readonly AUDIT_OPS: string;
  readonly POLICY_BIND: string;
  readonly POLICY_CONFIRM: string;
  readonly POLICY_SAVE: string;
  readonly AGREEMENTS_QUERY: string;
  readonly AGREEMENTS: string;
  readonly USER_ACCOUNTS_QUERY: string;
  readonly SALES_CHANNELS_QUERY: string;
  readonly SALES_CHANNELS: string;
  readonly TANANT_INSURER_QUERY: string;
  readonly CHANNEL_QUERY: string;
  readonly POLICY_ISSUE: string;
  readonly POLICY_OUTPUTS: string;
  readonly CROSS_SELL: string;
  readonly COMPARE_PRICE: string;
  readonly POLICIES_QUERY: string;
  readonly FETCH_INSURERS: string;
  readonly USERS: string;
  readonly HIERARCHIES: string;
  readonly LOAD_CMI_USAGE: string;
  readonly TENANT_INSURERS: string;
  readonly PRDT_CATEGORIES: string;
  readonly POLICY_ID: string;
  readonly POLICY_REJECT: string;
  readonly POLICY_UWS_BLACKLIST: string;
  readonly POLICY_UWS_CARINSPECTION: string;
  readonly POLICY_REFID_UWS_CARINSPECTION: string;
  readonly ENDORSEMENT_QUERY: string;
  readonly COMP_QUOTE_REJECT: string;
  readonly ENDO_NFN: string;
  readonly CUSTOMERSQ: string;
  readonly CUSTOMER_VIEW: string;
  readonly CUST_ENTRY: string;
  readonly CUST_LOAD: string;
  readonly PRODS_CATEGORIES: string;
  readonly PAY_METHOD: string;
  readonly BCP_TAGS: string;
  readonly BCP_COLLECTION_QUERY: string;
  readonly BCP_AGENT_COLLECTION_QUERY: string;
  readonly BCP_BILL_QUERY: string;
  readonly BCP_BILL_KNOCK_OFF_QUERY: string;
  readonly BANK_FILES_BANKS: string;
  readonly BANK_FILES_STATUS: string;
  readonly BP_SLIPS_STATUS: string;
  readonly REPORT: string;
  readonly ENDO_INCEPTION_CANCELLATION: string;
  readonly BCP_AGTACCOUNT_ME_CURRBAL: string;
  readonly BCP_ITEMSQ_UNBILLED: string;
  readonly BCP_AGTACCOUNT_ME_STATEMENTSQ: string;
  readonly BCP_AGTACCOUNTS_ME_STATEMENTS_ITEMSQ: string;
  readonly RETRY_DIRECT_DEBIT: string;
  readonly PRODUCT_CATES: string;
  readonly BCP_BANKACCOUNTSQ: string;
  readonly BCP_BILLING: string;
  readonly HQ_COLLECTE_PAY_METHOD: string;
  readonly COLLECTE_PAY_METHOD: string;
  readonly CUSTBILLS: string;
  readonly BPSLIPS: string;
  readonly ENDO_ID: string;
  readonly BANK_FILES_DETAIL: string;
  readonly BANK_FILE_STATUSES: string;
  readonly KNOCK_OFF_FILE_ERROR_CODES: string;
  readonly DIRECT_DEBIT_ERROR_CODES: string;
  readonly BCP_POLICY_COMINGBill: string;
  readonly BP_SLIPS_DETAIL: string;
  readonly BCP_DIRECTDEBIT: string;
  readonly BCP_BANKFILEES: string;
  readonly BCP_STATUSES: string;
  readonly BCP_DDD_MANUAL: string;
  readonly BCP_BANKFILES_DDR: string;
  readonly BCP_POLICY_PAYMENT_HISTORIES: string;
  readonly BCP_BILLSQ: string;

  readonly ENDOREEMENT_OUTPUTS: string;
  readonly UW_QUERY: string;
  readonly SAVE_PRICECHK: string;
  readonly SUBMIT_PRICECHK: string;
  readonly ASCODELIST: string;
  readonly BULK_UPLOAD: string;
  readonly BULK_ISSUE: string;
  readonly UPLOAD_STATUS: string;
  readonly REPORTS_RESULT: string;
  readonly SAVE_MANUAL_ISSUE: string;
  readonly ELIGIBLEPLANS: string;
  readonly BULK_CALC: string;
  readonly SANDBOX_POLICIES: string;
  readonly SANDBOX_BOX: string;
  readonly POLICIES_ELIGIBLEADDONS: string;

  readonly CLAIMS_FNOL_POLICIESQ: string;
  readonly GET_NOW: string;
  readonly SANDBOX_RESETTIME: string;
  readonly SANDBOX_TIMETRAVEL: string;
  readonly SANDBOX_JOBTRIGGER_BILLING: string;
  readonly SANDBOX_JOBTRIGGER_AGENTBULKBILLING: string;
  readonly SANDBOX_JOBTRIGGER_AGENTDDD: string;
  readonly SANDBOX_JOBTRIGGER_CUSTDDD: string;
  readonly SANDBOX_REBUILDES: string;

  readonly CLAIMS_FNOL: string;
  readonly LOAD_LOSS_ITEM: string;
  readonly CLAIMS: string;
  readonly CLAIMS_QUERY: string;
  readonly CLAIMS_VIEW: string;

  readonly COMPAREPRICE_OCCUPATIONCLASS: string;
  readonly COMPAREPRICE_GENDER: string;
  readonly COMPAREPRICE_ACCIDENT: string;
  readonly COMPAREPRICE_MASTERTABLE: string;

  readonly ENDO_MIDWAY_CANCELLATION: string;
  readonly ENDO_MIDWAY_CANCELLATION_INIT: string;

  readonly ADMIN_PARAMETERS: string;
  readonly ADMIN_PARAMETERS_SUMMARY: string;
  readonly RESP_TIME_TREND: string;
  readonly DAILY_COUNT: string;
  readonly INT_SUMMARY: string;
  readonly INT_LOGS_Q: string;
  readonly INT_LOGS_ID: string;
  readonly SS_ORG_Q: string;
};
/**
 * api url
 */
export default {
  LOGIN_IN: "/login",
  LOGO: "/logo",
  FAVICON: "/favicon",
  COMPANYNAME: "/companyname",
  FAIL_MORE: "/captcha",
  MASTER_TABLE: "/mastertable",
  NAME: "/mastertable",
  LOAD_POLICY: "/policies",
  LOAD_COMP_QUOTE: "/compareprice/quotes",
  BANKS: "/bcp/bankfiles/banks",
  BANK_ACCOUNT: "/users/me/bankaccount",
  OPT_EMAIL: "/otp/email",
  OPT_MOBILE: "/otp/mobile",
  SIGN_OUT: "/signout",
  CATEGORIES: "/prods/categories",
  LOAD_VEHICLE_USAGE: "/compareprice/thai/vmi/mastertable/usage",
  LOAD_CMI_USAGE: "/compareprice/thai/cmi/mastertable/usage",
  COMPARE_VMI: "/compareprice/thai/vmi",
  COMPARE_CMI: "/compareprice/thai/cmi",
  COMPARE_ACCIDENT: "/compareprice/thai/pa",
  COMPARE_TRAVEL: "/compareprice/thai/travel",
  COMPARE_VMI_BUY: "/compareprice/thai/vmi/buy",
  COMPARE_CMI_BUY: "/compareprice/thai/cmi/buy",
  COMPARE_PA_BUY: "/compareprice/thai/pa/buy",
  COMPARE_TRAVEL_BUY: "/compareprice/thai/travel/buy",
  CUSTOMER_VIEW: "/customers/:customerId",

  SANDBOX_POLICIES: "/sandbox/policies/issue/auto",
  USER_QUERY: "/usersq",
  USER_SESSION_QUERY: "/sessionsq",
  INVALIDATE_USER: "/sessions",

  SYS_LOG_QUERY: "/syslogsq",
  TASK_QUERY: "/tasksq",
  TASK_TYPES: "/tasks/types",
  TENANT_QUERY: "/tenantsq",
  TENANT: "/tenants",
  PRODS_SELLING_BROKER: "/prods/selling/broker",
  HIERARCHY_QUERY: "/tenants/hierarchiesq",
  INVALIDATE_HIERARCHY: "/tenants/hierarchies",
  QUERY_AUDIT_LOG: "/auditq",
  AUDIT_OPS: "/audit/ops",
  POLICY_BIND: "/policies/bind?autoUW=N",
  POLICY_CONFIRM: "/policies/bind",
  POLICY_SAVE: "/policies/merge",
  AGREEMENTS_QUERY: "/sc/agrmtsq",
  AGREEMENTS: "/sc/agrmts",
  USER_ACCOUNTS_QUERY: "/usersq",
  SALES_CHANNELS_QUERY: "/sc/saleschannelsq",
  SALES_CHANNELS: "/sc/saleschannels",
  TANANT_INSURER_QUERY: "/tenants/insurers",
  CHANNEL_QUERY: "/channels",
  POLICY_ISSUE: "/policies/issue",
  POLICY_OUTPUTS: "/policies/:policyId/outputs",
  CROSS_SELL: "/cross_sell/calc",
  COMPARE_PRICE: "/compareprice/quotes",
  POLICIES_QUERY: "/policiesq",
  FETCH_INSURERS: "/api/tenants/insurers",
  USERS: "/users",
  HIERARCHIES: "/tenants/hierarchies",
  TENANT_INSURERS: "/tenants/insurers",
  PRDT_CATEGORIES: "/prods/categories",
  POLICY_ID: "/policies/:policyId",
  ENDO_ID: "/endo/:endoId",
  POLICY_REJECT: "/policies/:policyId/reject",
  POLICY_UWS_BLACKLIST: "/policies/:refId/uws/blacklist",
  POLICY_REFID_UWS_CARINSPECTION: "/policies/:refId/uws/carinspection",
  POLICY_UWS_CARINSPECTION: "/policies/uws/carinspection",
  ENDORSEMENT_QUERY: "/endorsementsq",
  ENDOREEMENT_OUTPUTS: "/endo/:endoId/outputs",
  COMP_QUOTE_REJECT: "/compareprice/quotes/:compQuoteId/reject",
  ENDO_NFN: "/endo/nfn",
  CUSTOMERSQ: "/customersq",
  CUST_ENTRY: "/customers",
  CUST_LOAD: "/customers/:customerId",
  PRODS_CATEGORIES: "/prods/categories",
  REPORT: "/reports/:reportId/result",
  PAY_METHOD: "/bcp/bills/preferredpaymodes",
  BCP_TAGS: "/bcp/bills/tags",
  BCP_COLLECTION_QUERY: "/bcp/billsq",
  BCP_AGENT_COLLECTION_QUERY: "/bcp/agtbulkbillsq",
  BCP_BILL_QUERY: "/bcp/bpslipsq",
  BCP_BILL_KNOCK_OFF_QUERY: "/bcp/bankfilesq",
  BCP_POLICY_PAYMENT_HISTORIES: "/bcp/policy/payment/histories",
  BCP_POLICY_COMINGBill: "/bcp/policy/:policyId/comingbill",
  BANK_FILES_BANKS: "/bcp/bankfiles/banks",
  BANK_FILES_STATUS: "/bcp/bankfiles/statuses",
  BP_SLIPS_STATUS: "/bcp/bpslips/statuses",
  RETRY_DIRECT_DEBIT: "/bcp/bills/:billId/directdebit/retry",
  ENDO_INCEPTION_CANCELLATION: "/endo/cancel/inception",
  ENDO_MIDWAY_CANCELLATION: "/endo/cancel/midway",
  BCP_AGTACCOUNT_ME_CURRBAL: "/bcp/agtaccounts/me/currbal",
  BCP_ITEMSQ_UNBILLED: "/bcp/agtaccounts/me/statements/itemsq/unbilled",
  BCP_AGTACCOUNT_ME_STATEMENTSQ: "/bcp/agtaccounts/me/statementsq",
  BCP_AGTACCOUNTS_ME_STATEMENTS_ITEMSQ: "/bcp/agtaccounts/me/statements/itemsq",
  PRODUCT_CATES: "/prods/categories",
  BCP_BANKACCOUNTSQ: "/bcp/bankaccountsq",
  BCP_BILLING: "/bcp/:billingScheduleId/bankaccount",
  HQ_COLLECTE_PAY_METHOD: "/bcp/collect/paymodes/hq/manual",
  COLLECTE_PAY_METHOD: "/bcp/collect/paymodes/ac/manual",
  CUSTBILLS: "/bcp/collect/custbills/hq/manual",
  BPSLIPS: "/bcp/bpslips",
  BCP_BILLSQ: "/bcp/billsq",
  BANK_FILES_DETAIL: "/bcp/bankfiles/detailsq",
  BANK_FILE_STATUSES: "/bcp/bankfiles/records/statuses",
  KNOCK_OFF_FILE_ERROR_CODES: "/bcp/bankfiles/knockoff/errcodes",
  DIRECT_DEBIT_ERROR_CODES: "/bcp/bankfiles/directdebit/errcodes",
  BP_SLIPS_DETAIL: "/bcp/bpslips/:slipId",
  BCP_DIRECTDEBIT: "/bcp/bankfilesq",
  BCP_BANKFILEES: "/bcp/bankfiles/banks",
  BCP_STATUSES: "/bcp/bankfiles/statuses",
  BCP_DDD_MANUAL: "/bcp/bankfiles/ddd/manual",
  BCP_BANKFILES_DDR: "/bcp/bankfiles/ddr/:bankCode",
  UW_QUERY: "/policiesq",
  SAVE_PRICECHK: "/policies/uws/priceupdate",
  SUBMIT_PRICECHK: "/policies/uws/priceupdate",
  ASCODELIST: "/masterpoliciesq",
  BULK_UPLOAD: "/policies/bulk/upload",
  BULK_ISSUE: "/policies/bulk/issue",
  UPLOAD_STATUS: "/policies/bulk/files/:bulkFileId/progress",
  REPORTS_RESULT: "/reports/:reportId/result",
  SAVE_MANUAL_ISSUE: "/policies/uws/manualissue",
  ELIGIBLEPLANS: "/policies/eligibleplans",
  BULK_CALC: "/policies/bulk/calc",
  POLICIES_ELIGIBLEADDONS: "/policies/eligibleaddons",

  CLAIMS_FNOL_POLICIESQ: "/claims/fnol/policiesq",
  SANDBOX_BOX: "/sandbox/auth",
  GET_NOW: "/now",
  SANDBOX_RESETTIME: "/sandbox/resettime",
  SANDBOX_TIMETRAVEL: "/sandbox/timetravel/:time",
  SANDBOX_JOBTRIGGER_BILLING: "/sandbox/jobtrigger/billing",
  SANDBOX_JOBTRIGGER_AGENTBULKBILLING: "/sandbox/jobtrigger/agentbulkbilling",
  SANDBOX_JOBTRIGGER_CUSTDDD: "/sandbox/jobtrigger/custddd",
  SANDBOX_JOBTRIGGER_AGENTDDD: "/sandbox/jobtrigger/agentddd",
  SANDBOX_REBUILDES: "/sandbox/rebuildes",

  CLAIMS_FNOL: "/claims/fnol/:policyId/claims",
  LOAD_LOSS_ITEM: "/claims/lossitems?policyId=:policyId",
  CLAIMS: "/claims",
  CLAIMS_QUERY: "/claims/claimsq",
  CLAIMS_VIEW: "/claims/:claimsId",

  COMPAREPRICE_OCCUPATIONCLASS: "/compareprice/thai/pa/mastertable/occupationclass",
  COMPAREPRICE_GENDER: "/compareprice/thai/pa/mastertable/gender",
  COMPAREPRICE_MASTERTABLE: "/compareprice/thai/:productCode/mastertable/:tableName",

  ENDO_MIDWAY_CANCELLATION_INIT: "/endo/cancel/midway/init",

  ADMIN_PARAMETERS: "/parameters",
  ADMIN_PARAMETERS_SUMMARY: "/parameters/get_current_sale_rate",

  RESP_TIME_TREND: "/int_logs/stats/resp_time_trend",
  DAILY_COUNT: "/int_logs/stats/daily_count",
  INT_SUMMARY: "/int_logs/stats/summary",
  INT_LOGS_Q: "/int_logs/q",
  INT_LOGS_ID: "/int_logs/:logId",

  SS_ORG_Q: "/ss/organizationq",
} as APIS;

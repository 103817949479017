import { Ajax, Consts, Envs, PATH, Storage } from "@common";
import _ from "lodash";
import { History, React, Redirect, Route, Router, StyledF, Switch } from "@common/3rd";
import { Application, StyledPropsTheme } from "@my-types";
import Styles from "@styles";
import { writeSyslog } from "@common/sys-log";

// desk page
const Desk = React.lazy(() => import(/* webpackChunkName: "desk" */ "./desk"));

class App extends React.Component implements Application {
  history: History.History;
  styleRef: React.RefObject<any>;
  state: {
    themeName: string;
    globalStyles: React.ComponentClass<any>;
    theme: StyledPropsTheme;
  };

  constructor(props: any, context?: any) {
    super(props, context);
    this.history = History.createBrowserHistory({
      basename: process.env.REACT_APP_AJAX_CLIENT_CONTEXT || "",
    });
    this.styleRef = React.createRef();
    const themeName = Envs.getCurrentTheme();
    this.state = {
      themeName: themeName,
      globalStyles: Styles.createGlobalStyles(themeName),
      theme: Styles.getTheme(themeName),
    };

    this.history.listen(() => {
      window.scrollTo(0, 0);
    });
  }

  componentDidMount() {
    Envs.application(this);
    // 获取site key
    let envUrl = process.env.REACT_APP_AJAX_SERVER_HOST;
    if (process.env.REACT_APP_ENV_NAME === "Local") {
      envUrl = `${envUrl}:${process.env.REACT_APP_AJAX_SERVER_PORT}`;
    }
    Ajax.get(`${envUrl}/recaptchakey`).then((res) => {
      const siteKey = _.get(res, "body.respData", "");
      if (siteKey) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        document.head.appendChild(script);
      }
    });

    window.onerror = function(message, source, lineno, colno, error) {
      writeSyslog({
        logType: "Page Log",
        logLevel: "ERROR",
        message: `
          message： ${message};
          error： ${error};
          lineno： ${lineno}; colno： ${colno};
          source： ${source};
        `,
      });
    };
  }

  componentWillUnmount() {
    Envs.application(null);
  }

  private renderGlobalStyle(): JSX.Element {
    const GloablStyles = this.state.globalStyles;
    return <GloablStyles ref={this.styleRef as any}/>;
  }

  render() {
    return (
      <React.Fragment>
        {this.renderGlobalStyle()}
        <StyledF.ThemeProvider theme={this.getTheme()}>
          <React.Suspense fallback={<div/>}>
            <Router history={this.history}>
              <Switch>
                <Route path="/*" render={() => <Desk/>}/>
              </Switch>
            </Router>
          </React.Suspense>
        </StyledF.ThemeProvider>
      </React.Fragment>
    );
  }

  getHistory(): History.History {
    return this.history;
  }

  getTheme(): StyledPropsTheme {
    return this.state.theme;
  }

  getThemeName(): string {
    return this.state.themeName;
  }

  changeTheme(name: string): void {
    if (!name || name === this.state.themeName) {
      return;
    }
    if (name.startsWith("style-")) {
      name = name.substring(6);
    }
    if (name === this.state.themeName) {
      return;
    }

    Storage.Theme.set(Consts.THEME_KEY, name);
    this.setState({
      gloablStyle: Styles.createGlobalStyles(name),
      theme: Styles.getTheme(name),
      themeName: name,
    });
  }
}

export default App;

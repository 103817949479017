import moment from "moment";
import _ from "lodash";
import Consts from "./consts";
import language from "./language";
import React from "react";
import { Ajax } from "@common/index";

const { DATE_FORMAT, CURRENCY_SYMBOL_TABLE, PAYMENT_METHOD_INSTALLMENT } = Consts;

type QS = {
  ticket?: string | null;
  token?: string | null;
  delegated?: string | null;
  theme?: string | null;
  authFailTo?: string | null;
  rootUrlFrom?: string | null;
  urlFrom?: string | null;
  parcel?: string | null;
};

/**
 * copy from https://github.com/AceMetrix/jquery-deparam
 */
const deparam = (params?: string | null, coerce: boolean = false) => {
  var obj: any = {};
  var coerce_types: any = { true: !0, false: !1, null: null };

  // If params is an empty string or otherwise falsy, return obj.
  if (!params) {
    return obj;
  }

  // Iterate over all name=value pairs.
  params
    .replace(/\+/g, " ")
    .split("&")
    .forEach(function(v) {
      var param: string[] = v.split("=");
      var key: string = decodeURIComponent(param[0]);
      var val: any;
      var cur: any = obj;
      var i: number = 0;
      // If key is more complex than 'foo', like 'a[]' or 'a[b][c]', split it
      // into its component parts.
      var keys: any[] = key.split("][");
      var keys_last: number = keys.length - 1;

      // If the first keys part contains [ and the last ends with ], then []
      // are correctly balanced.
      if (/\[/.test(keys[0]) && /\]$/.test(keys[keys_last])) {
        // Remove the trailing ] from the last keys part.
        keys[keys_last] = keys[keys_last].replace(/\]$/, "");

        // Split first keys part into two parts on the [ and add them back onto
        // the beginning of the keys array.
        keys = keys
          .shift()
          .split("[")
          .concat(keys);

        keys_last = keys.length - 1;
      } else {
        // Basic 'foo' style key.
        keys_last = 0;
      }

      // Are we dealing with a name=value pair, or just a name?
      if (param.length === 2) {
        val = decodeURIComponent(param[1]);

        // Coerce values.
        if (coerce) {
          val =
            val && !isNaN(val) && +val + "" === val
              ? +val // number
              : val === "undefined"
              ? undefined // undefined
              : coerce_types[val] !== undefined
                ? coerce_types[val] // true, false, null
                : val; // string
        }

        if (keys_last) {
          // Complex key, build deep object structure based on a few rules:
          // * The 'cur' pointer starts at the object top-level.
          // * [] = array push (n is set to array length), [n] = array if n is
          //   numeric, otherwise object.
          // * If at the last keys part, set the value.
          // * For each keys part, if the current level is undefined create an
          //   object or array based on the type of the next keys part.
          // * Move the 'cur' pointer to the next level.
          // * Rinse & repeat.
          for (; i <= keys_last; i++) {
            key = keys[i] === "" ? cur.length : keys[i];
            cur = cur[key] = i < keys_last ? cur[key] || (keys[i + 1] && isNaN(keys[i + 1]) ? {} : []) : val;
          }
        } else {
          // Simple key, even simpler rules, since only scalars and shallow
          // arrays are allowed.

          if (Object.prototype.toString.call(obj[key]) === "[object Array]") {
            // val is already an array, so push on the next value.
            obj[key].push(val);
          } else if ({}.hasOwnProperty.call(obj, key)) {
            // val isn't an array, but since a second value has been specified,
            // convert val into an array.
            obj[key] = [obj[key], val];
          } else {
            // val is a scalar.
            obj[key] = val;
          }
        }
      } else if (key) {
        // No value was defined, so set something meaningful.
        obj[key] = coerce ? undefined : "";
      }
    });

  return obj;
};

/**
 * copy from https://github.com/knowledgecode/jquery-param
 */
const param = (a: any): string => {
  var s: string[] = [];
  var add = function(k: string, v: any) {
    v = typeof v === "function" ? v() : v;
    v = v === null ? "" : v === undefined ? "" : v;
    s[s.length] = encodeURIComponent(k) + "=" + encodeURIComponent(v);
  };
  var buildParams = function(prefix: string, obj: any) {
    var i, len, key;

    if (prefix) {
      if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          buildParams(prefix + "[" + (typeof obj[i] === "object" && obj[i] ? i : "") + "]", obj[i]);
        }
      } else if (String(obj) === "[object Object]") {
        for (key in obj) {
          buildParams(prefix + "[" + key + "]", obj[key]);
        }
      } else {
        add(prefix, obj);
      }
    } else if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value);
      }
    } else {
      for (key in obj) {
        buildParams(key, obj[key]);
      }
    }
    return s;
  };

  return buildParams("", a).join("&");
};

class Utils {
  toArray<T>(any: null | undefined | T | T[]): T[] {
    return Array.isArray(any) ? any : any == null ? [] : [any];
  }

  times<T>(element: T, times?: number): T[] {
    const ret = new Array<T>(times || 1);
    ret.fill(element, 0, ret.length);
    return ret;
  }

  truncateAsDate(date: string): string {
    return date ? date.substring(0, 11) : date;
  }

  formatAmount(currency?: number | string) {
    if (currency != null) {
      return (currency + "").toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    } else {
      return "";
    }
  }

  isEmpty(o?: string | number | null) {
    return !this.isNumber(o) && (this.isNull(o) || this.isUndefined(o) || o.length == 0);
  }

  isString(o: any): o is string {
    return Object.prototype.toString.call(o).slice(8, -1) === "String";
  }

  isNumber(o: any): o is number {
    return Object.prototype.toString.call(o).slice(8, -1) === "Number";
  }

  isBoolean(o: any): o is boolean {
    return Object.prototype.toString.call(o).slice(8, -1) === "Boolean";
  }

  isFunction(o: any): o is Function {
    return Object.prototype.toString.call(o).slice(8, -1) === "Function";
  }

  isNull(o: any): o is null {
    return Object.prototype.toString.call(o).slice(8, -1) === "Null";
  }

  isUndefined(o: any): o is undefined {
    return Object.prototype.toString.call(o).slice(8, -1) === "Undefined";
  }

  isObject(o: any): o is object {
    return Object.prototype.toString.call(o).slice(8, -1) === "Object";
  }

  isArray(o: any): o is any[] {
    return Array.isArray(o);
  }

  isDate(o: any): o is Date {
    return Object.prototype.toString.call(o).slice(8, -1) === "Date";
  }

  isRegExp(o: any): o is RegExp {
    return Object.prototype.toString.call(o).slice(8, -1) === "RegExp";
  }

  isError(o: any): o is Error {
    return Object.prototype.toString.call(o).slice(8, -1) === "Error";
  }

  isSymbol(o: any): o is Symbol {
    return Object.prototype.toString.call(o).slice(8, -1) === "Symbol";
  }

  isPromise(o: any): o is Promise<any> {
    return Object.prototype.toString.call(o).slice(8, -1) === "Promise";
  }

  isSet(o: any): o is Set<any> {
    return Object.prototype.toString.call(o).slice(8, -1) === "Set";
  }

  isFormData(o: any): o is FormData {
    return Object.prototype.toString.call(o).slice(8, -1) === "FormData";
  }

  isFalse(o: any): o is false {
    return !o || o === "null" || o === "undefined" || o === "false" || o === "NaN";
  }

  isTrue(o: any): o is true {
    return !this.isFalse(o);
  }

  fromQueryString(qs?: string | null): QS | any {
    if (!this.isNull(qs) && !this.isUndefined(qs)) {
      return deparam(qs);
    } else {
      const search = window.location.search;
      if (search && search !== "?") {
        return deparam(search.substring(1));
      } else {
        return {};
      }
    }
  }

  toQueryString(obj: any): string {
    return param(obj);
  }

  encodeCurrentURI(qs?: string | null): string {
    if (this.isNull(qs) || this.isUndefined(qs)) {
      return encodeURIComponent(window.location.origin + window.location.pathname);
    } else {
      return encodeURIComponent(`${window.location.origin}${window.location.pathname}?${qs}`);
    }
  }

  copyTextToClipboard(s: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const textarea = document.createElement("textarea");
      // textarea.style.display = 'none';
      textarea.style.position = "fixed";
      textarea.style.height = "0";
      textarea.style.top = "-9999px";
      textarea.value = s;
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, s.length);
      const ret = document.execCommand("copy");
      document.body.removeChild(textarea);
      if (ret) {
        resolve();
      } else {
        reject();
      }
    });
  }

  convertToMoment(value: any): moment.Moment {
    if (moment.isMoment(value)) {
      return value;
    }

    if (value == null) {
      return moment.invalid();
    }

    return moment(value, DATE_FORMAT.DATE_TIME_WITH_TIME_ZONE);
  }

  dateFromNow(value: any): string {
    return this.convertToMoment(value).fromNow();
  }

  parseDate(value: any): Date {
    return this.convertToMoment(value).toDate();
  }

  dateTimeFromNow(value: any): string {
    if (value) {
      return this.convertToMoment(value).fromNow();
    } else {
      return "";
    }
  }

  formatDate(value: any, formatStr = DATE_FORMAT.DATE_FORMAT): string {
    return moment(value, DATE_FORMAT.DATE_FORMAT).format(formatStr);
  }

  formatDateTime(value: any, formatStr = DATE_FORMAT.DATE_TIME_FORMAT_NOMAL): string {
    return this.convertToMoment(value).format(formatStr);
  }

  stringifyDate(value: any): string {
    if (value) {
      return this.convertToMoment(value).format(DATE_FORMAT.DATE_TIME_WITH_TIME_ZONE);
    }

    return "";
  }

  toThousands(num: any) {
    num = num + "";
    if (!num.includes(".")) {
      num += ".";
    }
    return num
      .replace(/(\d)(?=(\d{3})+\.)/g, function($0: any, $1: any) {
        return $1 + ",";
      })
      .replace(/\.$/, "");
  }

  formatCurrencyInfo(amount: any, currencyCode: any, digits: number = 2): any {
    const currencyText = !currencyCode ? "" : CURRENCY_SYMBOL_TABLE[currencyCode];
    let format = "0,0";
    for (let index = 0; index < digits; index++) {
      if (index === 0) {
        format = format + ".0";
      } else {
        format = format + "0";
      }
    }
    if (!amount) {
      return {
        amount: "0.00",
        currencyText,
      };
    }
    return {
      amount: this.toThousands(amount.toFixed(2)),
      currencyText,
    };
  }

  formatCurrency(amount: any, currencyCode?: any, digits = 2) {
    const currencyText = !currencyCode ? "" : CURRENCY_SYMBOL_TABLE[currencyCode];
    const negativeSign = amount && amount < 0 ? "-" : "";
    let format = "0,0";
    for (let index = 0; index < digits; index++) {
      if (index === 0) {
        format = format + ".0";
      } else {
        format = format + "0";
      }
    }
    return `${negativeSign}${currencyText} ${this.toThousands(Math.abs(amount).toFixed(2))}`;
  }

  getValueFromJSON(jsonObject: any = {}, id: string) {
    if (id.indexOf(".") === -1) return jsonObject[id];
    var ids = id.split(".");
    var parent = jsonObject;
    var value = null;
    var values = ids.map(function(id) {
      if (parent == null) {
        return null;
      } else {
        value = parent[id];
        parent = value;
        return value;
      }
    });
    return values[values.length - 1];
  }

  checkPlanPremiumIsZero(plan: any) {
    let lumpsumAr = this.getValueFromJSON(plan, "policyPremium.lumpsum.ar") || 0;
    // 分期付款，不止是用于月付
    let subsequentAr = this.getValueFromJSON(plan, "policyPremium.subsequent.ar") || 0;
    return lumpsumAr === 0 && subsequentAr === 0;
  }

  getPolicyPremium(policy: any = {}, paymentSchedule: any = null) {
    const { openEnd, installmentPayment } = policy;
    let lumpsum = this.getValueFromJSON(policy, "policyPremium.lumpsum") || {};
    let subsequent = this.getValueFromJSON(policy, "policyPremium.subsequent") || {};
    if (openEnd || installmentPayment || paymentSchedule === PAYMENT_METHOD_INSTALLMENT) {
      return subsequent;
    }
    return lumpsum;
  }

  renderPolicyPremium(policy: any = {}, paymentSchedule: any = null) {
    const { openEnd, installmentPayment } = policy;
    const policyPremium = this.getPolicyPremium(policy);
    const { amount, currencyText } = this.formatCurrencyInfo(policyPremium.ar, policy.currencyCode);
    const isInstallmentPayment = openEnd || installmentPayment || paymentSchedule === PAYMENT_METHOD_INSTALLMENT;
    // 分期付款，不止是用于月付
    const premiumDesc = policy.premiumDesc || "month";
    return (
      <span
        className="price price--lg"
        data-postfix={isInstallmentPayment ? `/ ${premiumDesc ? premiumDesc : "month"}` : ""}
        data-prefix={currencyText}
      >
        {amount}
      </span>
    );
  }

  renderDownpaymentPremium(policy: any = {}) {
    let ar = this.getValueFromJSON(policy, "policyPremium.downpayment.ar");
    const { amount, currencyText } = this.formatCurrencyInfo(ar, policy.currencyCode);
    return (
      <span className="price price--lg" data-prefix={currencyText}>
        {amount}
      </span>
    );
  }

  redirectToPayPage(html: any): void {
    const wrap = document.createElement("div");
    wrap.innerHTML = html;
    document.body.appendChild(wrap);
    wrap.querySelector("form")!.submit();
  }

  dateTimeInit(value: any) {
    if (value) {
      const mValue = moment(value, Consts.DATE_FORMAT.DATE_TIME_WITH_TIME_ZONE);
      return mValue.toDate();
    } else {
      return new Date();
    }
  }

  toDateString(value: any) {
    if (value) {
      const mValue = moment(value, Consts.DATE_FORMAT.DATE_TIME_WITH_TIME_ZONE);
      return mValue.format(Consts.DATE_FORMAT.DATE_FORMAT);
    } else {
      return "";
    }
  }

  toTimeString(value: any) {
    if (value) {
      const mValue = moment(value, Consts.DATE_FORMAT.DATE_TIME_WITH_TIME_ZONE);
      return mValue.format("HH:mm:ss");
    } else {
      return "";
    }
  }

  toDateTimeString(value: any, format = "DD/MM/YYYYTHH:mm:ssZ") {
    if (value) {
      const date = typeof value === "string" ? this.dateTimeInit(value) : value;

      const mValue = moment(date);
      return mValue.format(format);
    } else {
      return "";
    }
  }

  joinElements(list: any, separator: any) {
    if (!list || list.length <= 1) {
      return list;
    }

    return list
      .slice(0, -1)
      .reduce(function(acc: any, cur: any, index: number) {
        return acc.concat([cur, React.cloneElement(separator, { key: index })]);
      }, [])
      .concat(list.slice(-1));
  }

  getParamsFromUrl(search = window.location.href) {
    const paramsStr = search.split("?")[1];
    if (paramsStr) {
      const paramsArr = paramsStr.split("&");
      const obj: any = {};

      paramsArr.forEach(p => {
        const keyValue = p.split("=");

        obj[keyValue[0]] = keyValue[1];
      });
      return obj;
    }
    return null;
  }

  getIsMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|PlayBook|BlackBerry/i.test(navigator.userAgent)) {
      if (document.body.scrollWidth >= 768) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getPureMobile() {
    return /Android|webOS|iPhone|iPad|iPod|PlayBook|BlackBerry/i.test(navigator.userAgent);
  }

  getWhith() {
    if (window.innerWidth) {
      return window.innerWidth;
    } else {
      if (document.compatMode == "CSS1Compat") {
        return document.documentElement.clientWidth;
      } else {
        return document.body.clientWidth;
      }
    }
  }

  getHeight() {
    if (window.innerHeight) {
      return window.innerHeight;
    } else {
      if (document.compatMode == "CSS1Compat") {
        return document.documentElement.clientHeight;
      } else {
        return document.body.clientHeight;
      }
    }
  }

  bodyScale() {
    const scale = this.getWhith() > 1200 || this.getWhith() < 768 ? 1 : this.getWhith() / 1200;
    let container: any = document.querySelector(".page-body");
    if (container) {
      try {
        container.childNodes[0].style.minHeight = (this.getHeight() * (1 / scale) - 196 * scale) + "px";
        container.childNodes[0].style.backgroundColor = "#fff";
      } catch (e) {

      }
    }
  }

  bodyScaleContainer() {
    const scale = this.getWhith() > 1200 || this.getWhith() < 768 ? 1 : this.getWhith() / 1200;
    let container: any = document.querySelector(".page-body .container");
    if (container) {
      try {
        container.style.minHeight = (this.getHeight() * (1 / scale) - 196 * scale) + "px";
      } catch (e) {

      }
    }
  }

  getMonthEn(month: number) {
    let months: any[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    if (!month && month !== 0) {
      return months[new Date().getMonth()];
    }
    return months[month - 1] || months[new Date().getMonth()];
  }

  returnNotNullValue(value: any) {
    return this.isFalse(value) ? "" : value;
  }

  strThaiCharLen(str: any) {
    let n = str.length, c, s = 0;
    while (n--) {
      c = str.charCodeAt(n);
      switch (true) {
        case c <= 0x7F:
          s += 1;
          break;
        case c <= 0x7FF:
          s += 1;
          break;
        case c <= 0xFFFF:
          s += 1;
          break;
        case c <= 0x1FFFF:
          s += 1;
          break;
        case c <= 0x7FFFFFFFF:
          s += 1;
          break;
      }
    }
    return s;
  }

  //判断浏览器类型和版本
  getBrowserVersion(userAgent: any) {
    // = navigator.userAgent
    let version: any = "";
    if (userAgent.indexOf("Firefox") > -1) {
      version = userAgent.match(/firefox\/[\d.]+/gi)[0].match(/[\d]+/)[0];
      return "Firefox " + version;
    } else if (userAgent.indexOf("Edge") > -1) {
      version = userAgent.match(/edge\/[\d.]+/gi)[0].match(/[\d]+/)[0];
      return "Edge " + version;
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
      if (userAgent.indexOf("Opera") > -1) {
        version = userAgent.match(/opera\/[\d.]+/gi)[0].match(/[\d]+/)[0];
        return "Opera " + version;
      }
      if (userAgent.indexOf("OPR") > -1) {
        version = userAgent.match(/opr\/[\d.]+/gi)[0].match(/[\d]+/)[0];
        return "Opera " + version;
      }
    } else if (userAgent.indexOf("Chrome") > -1) {
      version = userAgent.match(/chrome\/[\d.]+/gi)[0].match(/[\d]+/)[0];
      return "Chrome " + version;
    } else if (userAgent.indexOf("Safari") > -1) {
      version = userAgent.match(/safari\/[\d.]+/gi)[0].match(/[\d]+/)[0];
      return "Safari " + version;
    } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
      if (userAgent.indexOf("MSIE") > -1) {
        version = userAgent.match(/msie [\d.]+/gi)[0].match(/[\d]+/)[0];
        return "IE " + version;
      }
      if (userAgent.indexOf("Trident") > -1) {
        let versionTrident = userAgent.match(/trident\/[\d.]+/gi)[0].match(/[\d]+/)[0];
        version = parseInt(versionTrident) + 4;
        return "IE " + version;
      }
    }
    return userAgent;
  }


  tokenRecaptcha(action: any) {
    let envUrl = process.env.REACT_APP_AJAX_SERVER_HOST;
    if (process.env.REACT_APP_ENV_NAME === "Local") {
      envUrl = `${envUrl}:${process.env.REACT_APP_AJAX_SERVER_PORT}`;
    }
    return Ajax.get(`${envUrl}/recaptchakey`).then(async (res: any,
    ) => {
      let tokenRe = "0123456789";
      const key = _.get(res, "body.respData", "");
      if (process.env.REACT_APP_ENV_NAME === "PROD" || process.env.REACT_APP_ENV_NAME === "UAT") {
        await grecaptchaReady();
        try {
          const token = await (window as any).grecaptcha.execute(key, { action });
          tokenRe = token;
        } catch (e) {
        }
        return tokenRe;
      }
      return tokenRe;
    }).catch(() => {
      return "0123456789";
    });
  }

  replaceSpaceString(value: string) {
    if (!!value) return value.replace(/[\u200B-\u200D\uFEFF]/g, '');
    return value;
  }
}

export default new Utils();

const _grecaptchaReady = (resolve: any) => {
  (window as any).grecaptcha.ready(() => {
    resolve(true);
  });
};

const grecaptchaReady = () => {
  return new Promise((resolve: any, reject: any) => {
    _grecaptchaReady(resolve);
  });
};

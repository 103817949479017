import _ from "lodash";
import { getClientInfo } from "@common/ajax-before";
import { ISyslog } from "@common/sys-log/types";
import { Ajax } from "@common";


function getBrowserInfo() {
  const clientInfo = getClientInfo();
  return {
    clientOs: clientInfo["x-insmate-client-os"],
    browser: clientInfo["x-insmate-client-browser"],
    browserVersion: _.get(navigator, "appVersion"), //(KHTML, like Gecko) Version/12.1.2
  };
}


export function getUrlInfo(windowProps: any = window) {
  return {
    domainName: _.get(windowProps, "location.host"),
    pageUrl: _.get(windowProps, "location.pathname"),
  };
}


export function writeSyslog(syslogArgument: ISyslog = {}) {
  const syslog: ISyslog = {
    ...getBrowserInfo(),
    ...getUrlInfo(),
    ...syslogArgument,
  };
  console.log("ISyslog: ", syslog);
  Ajax.post("/syslogs/write", syslog);
}

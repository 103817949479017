function formatInThousandSeparator(str: string) {
  if (str === null || str === undefined) return "";
  return `${str}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function parseCurrency(str: string, currencySymbol: string) {
  return str.replace(eval("/\\" + currencySymbol + "\\s?|(,*)/g"), "");
}

export default {
  formatInThousandSeparator,
  parseCurrency,
};
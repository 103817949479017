// react
import React from "react";
import ReactDOM from "react-dom";
import * as History from "history";
import { Router, Route, Link, Switch, Redirect } from "react-router-dom";
import { Transition, CSSTransition, TransitionGroup } from "react-transition-group";

// fundamental
import Styled, * as StyledFunctions from "./common-3rd/styled-components-wrapper";

export {
  // react
  Router,
  Route,
  Link,
  Switch,
  Redirect,
  History,
  React,
  ReactDOM,
  Transition,
  CSSTransition,
  TransitionGroup,
  // fundamental
  Styled,
  StyledFunctions as StyledF
};

type PATHS = {
  readonly DESK_HOME: string;
  readonly ERROR_403: string;
  readonly ERROR_404: string;
  readonly ERROR_500: string;
  readonly SIGN_IN: string;
  readonly FORGET_PASS: string;
  readonly DASHBOARD: string;
  readonly PROFILE: string;
  readonly POLICYLIST: string;
  readonly ACCOUNT_PROFILE: string;
  readonly ACCOUNT_LOGIN: string;
  readonly ACCOUNT_AUTHORITY: string;
  readonly ACCOUNT_BANK: string;

  readonly QUOTE_ENTRY: string;
  readonly QUOTE_LIFE: string;
  readonly COMPARE_QUOTE: string;
  readonly COMPARE_QUOTE_CONTINUE: string;

  readonly QUOTE_SHARE: string;
  readonly NORMAL_QUOTE: string;
  readonly QUOTE_VIEW: string;
  readonly QUOTE_VIEW_PH: string;
  readonly QUOTE_VIEW_VMI: string;
  readonly QUOTE_VIEW_CMI: string;
  readonly QUOTE_VIEW_GCH: string;
  readonly QUOTE_VIEW_GFT: string;
  readonly QUOTE_VIEW_WLSC: string;
  readonly QUOTE_VIEW_PA: string;
  readonly QUOTE_VIEW_GHM: string;
  readonly QUOTE_VIEW_FLA: string;
  readonly QUOTE_VIEW_GTA: string;
  readonly QUOTE_NEW_QUOTE: string;

  readonly ADMIN: string;
  readonly ADMIN_HOME: string;
  readonly ADMIN_USER_SESSION: string;
  readonly ADMIN_TRACE_TASK: string;
  readonly ADMIN_SYS_LOG: string;
  readonly ADMIN_TENANT: string;
  readonly ADMIN_HIERARCHY: string;
  readonly ADMIN_HIERARCHY_ENTITY: string;
  readonly ADMIN_SALESCHANNEL_ENTITY: string;
  readonly ADMIN_AGREEMENT_ENTITY: string;
  readonly ADMIN_QUERY_AUDIT_LOG: string;
  readonly ADMIN_AGREEMENTS: string;
  readonly ADMIN_SALES_CHANNELS: string;
  readonly ADMIN_USER_ACCOUNTS: string;
  readonly ADMIN_USER_ACCOUNTS_ENTITY: string;
  readonly ADMIN_TENANT_ENTITY: string;
  readonly ADMIN_ADMIN_DEMO_DATA: string;
  readonly ADMIN_RENEWAL: string;
  readonly ADMIN_CHANGE_PARAM: string;
  readonly INTEGRATION_LOG: string;

  readonly POLICIES_QUERY: string;
  readonly POLICIES_BLACKLIST: string;
  readonly UW_BLACKLIST: string;
  readonly POLICIES_INSPECTION: string;
  readonly UW_CAR_INSPECTION: string;

  readonly ENDORSEMENTS_QUERY: string;
  readonly CUSTOMERS_LIST: string;
  readonly CUSTOMERS_ENTRY: string;
  readonly CUSTOMERS_VIEW: string;
  readonly ENDORSEMENT_ENTRY: string;
  readonly ENDORSEMENT_VIEW: string;
  readonly BCP_MANAGE_PANYMNET: string;
  readonly BCP_COLLECTION: string;
  readonly BCP_HQ_COLLECTION: string;
  readonly BCP_AGENT_COLLECTION: string;
  readonly BCP_BILL_PAYMENT: string;
  readonly BCP_MY_ACCOUNT: string;
  readonly BCP_BANK_ACCOUNT: string;
  readonly BCP_REFERRAL_FEE: string;
  readonly BCP_DIRECT_DEBIT: string;
  readonly UNDER_WRITING_QUERY: string;
  readonly UW_OFFLINE_ENTRY: string;
  readonly GROUP_FLEET: string;
  readonly GROUP_CANCER: string;
  readonly REPORT: string;
  readonly UW_OFFLINE_SUCCESS: string;

  readonly CLAIMS_CALLCENTER: string;
  readonly CLAIMS_QUERY: string;
  readonly CLAIMS_FONL: string;
  readonly CLAIMS_VIEW: string;
  readonly QUOTE_QC_VMI: string;
  readonly QUOTE_QC_CMI: string;
};

export default {
  DESK_HOME: "/home",
  DASHBOARD: "/home/dashboard",
  ERROR_403: "/exception/403",
  ERROR_404: "/exception/404",
  ERROR_500: "/exception/500",
  SIGN_IN: "/sign/in",
  FORGET_PASS: "/forgotpwd",
  PROFILE: "/settings",
  ACCOUNT_PROFILE: "/settings/profile",
  ACCOUNT_LOGIN: "/settings/login",
  ACCOUNT_AUTHORITY: "/settings/authority",
  ACCOUNT_BANK: "/settings/bank",
  QUOTE_ENTRY: "/quote",
  QUOTE_LIFE: "/quote/life/plan",
  QUOTE_SHARE: "/quote/payment/share/:policyId",

  //Compare routers
  COMPARE_QUOTE: "/quote/compare/:prdtCode",
  COMPARE_QUOTE_CONTINUE: "/quote/compare/:prdtCode/:policyId/:bizType/:paymentStatus?",

  //normal crate quote routers
  NORMAL_QUOTE: "/quote/:itntCode/:prdtCode/:policyId?",

  //view quote router
  QUOTE_VIEW: "/quote/view/:itntCode/:prdtCode/:policyId",
  QUOTE_VIEW_VMI: "/quote/view/VMI/:policyId",
  QUOTE_VIEW_PH: "/quote/view/PH/:policyId",
  QUOTE_VIEW_CMI: "/quote/view/CMI/:policyId",
  QUOTE_VIEW_GCH: "/quote/view/GCH/:policyId",
  QUOTE_VIEW_GFT: "/quote/view/GFT/:policyId",
  QUOTE_VIEW_WLSC: "/quote/view/WLSC/:policyId",
  QUOTE_VIEW_FLA: "/quote/view/FLA/:policyId",
  QUOTE_VIEW_PA: "/quote/view/PA/:policyId",
  QUOTE_VIEW_GTA: "/quote/view/GTA/:policyId",
  QUOTE_VIEW_GHM: "/quote/view/GHM/:policyId",
  QUOTE_NEW_QUOTE: "/transactions/new",
  UW_BLACKLIST: "/uw/blacklist/approval/:refId",
  UW_CAR_INSPECTION: "/uw/carInspection/:refId",
  QUOTE_QC_VMI: "/quote/qc/VMI/:policyId",
  QUOTE_QC_CMI: "/quote/qc/CMI/:policyId",

  ADMIN: "/admin",
  ADMIN_HOME: "/admin/home",
  ADMIN_USER_SESSION: "/admin/usersessions",
  ADMIN_TRACE_TASK: "/admin/tracetasks",
  ADMIN_SYS_LOG: "/admin/syslogs",
  ADMIN_TENANT: "/admin/tenants",
  ADMIN_TENANT_ENTITY: "/admin/tenant",
  ADMIN_HIERARCHY: "/admin/hierarchies",
  ADMIN_HIERARCHY_ENTITY: "/admin/hierarchy",
  ADMIN_SALESCHANNEL_ENTITY: "/admin/saleschannel",
  ADMIN_AGREEMENT_ENTITY: "/admin/agreement",
  ADMIN_QUERY_AUDIT_LOG: "/admin/auditlogs",
  ADMIN_AGREEMENTS: "/admin/agreements",
  ADMIN_SALES_CHANNELS: "/admin/saleschannels",
  ADMIN_USER_ACCOUNTS: "/admin/useraccounts",
  ADMIN_ADMIN_DEMO_DATA: "/admin/demoData",
  ADMIN_RENEWAL: "/renewal/upload",
  ADMIN_CHANGE_PARAM: "/admin/changeParams",
  INTEGRATION_LOG: "/admin/integration-logs",

  POLICIES_QUERY: "/transactions/policyList",
  POLICIES_BLACKLIST: "/transactions/blacklist",
  POLICIES_INSPECTION: "/transactions/inspection",

  ENDORSEMENTS_QUERY: "/transactions/endorsements",
  ENDORSEMENT_ENTRY: "/endorsement/:endoType/:policyId",
  ENDORSEMENT_VIEW: "/endorsements/view/:endoId",

  ADMIN_USER_ACCOUNTS_ENTITY: "/admin/useraccount",
  CUSTOMERS_LIST: "/customers/list",
  CUSTOMERS_ENTRY: "/customers/entry/:customerId?",
  CUSTOMERS_VIEW: "/customers/view/:customerId?",
  BCP_COLLECTION: "/bcp/collection",
  BCP_MANAGE_PANYMNET: "/bcp/payment",
  BCP_HQ_COLLECTION: "/bcp/hqcollection",
  BCP_AGENT_COLLECTION: "/bcp/agentcollection",
  BCP_BILL_PAYMENT: "/bcp/billPayment",
  BCP_MY_ACCOUNT: "/bcp/myAccounts",
  BCP_BANK_ACCOUNT: "/bcp/bankAccount",
  BCP_DIRECT_DEBIT: "/bcp/autoDebit",
  BCP_REFERRAL_FEE: "/bcp/referralFee",
  UNDER_WRITING_QUERY: "/transactions/uw",
  UW_OFFLINE_ENTRY: "/uw/:uwType/:refId",
  GROUP_FLEET: "/quote/fleet/group",
  GROUP_CANCER: "/quote/health/group",
  REPORT: "/reports",
  UW_OFFLINE_SUCCESS: "/uw/offlineIssue/success",

  CLAIMS_CALLCENTER: "/claims/callcenter",
  CLAIMS_QUERY: "/claims/query",
  CLAIMS_FONL: "/claims/fnol",
  CLAIMS_VIEW: "/claims/view",
} as PATHS;

import parent from "./standard";
import {StyledPropsTheme} from "@my-types";

const green = Object.assign({}, parent, {
    COLOR_PRIMARY: "#52c41a",
    COLOR_PRIMARY_LIGHT_O75: "rgba(226, 98, 39, 0.75)",
    COLOR_PRIMARY_LIGHT_010: "rgba(226, 98, 39, 0.1)",
    COLOR_PRIMARY_LIGHT: "#e88354",
    INPUT_FOCUS_BORDER_COLOR: "rgba(226, 98, 39, 0.8)",
    TOGGLE_TRUE_BORDER_COLOR: "#e26227",
    TOGGLE_TRUE_BACKGROUND_COLOR: "#e26227"
}) as StyledPropsTheme;

export default green;

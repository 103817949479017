import { StyledPropsTheme } from '@my-types';
import Green from './green';

export default {
	DESK_PAGE_HEADER_HEIGHT: 54,
	PAGE_HEADER_Z_INDEX: 51,
	PAGE_FOOTER_Z_INDEX: 50,
	// font ****************************************************
	FONT_SIZE_HTML: 14,
	FONT_SIZE: 14,
	FONT_FAMILY:
		"-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
	FONT_WEIGHT: 300,
	FONT_COLOR: '#555',
	// color ****************************************************
	COLOR_BACKGROUND: '#f6f6f6',
	ICON_WIDTH: 40,
	INPUT_HEIGHT: 32,

	COLOR_PRIMARY: '#4db8ea',
	BACKGROUND_COLOR: '#5fbfec',
	BORDER_COLOR: '#5fbfec',
	HEADER_COLOR: '#4db8ea',
	HIGHLIGHT_COLOR: '#5fbfec',
	HREF_A: '#85dcff',
	COLOR_BACKGROUND_BTN: '#5fbfec',
	COLOR_A: '#57c2f4',
	UPLOAD_BOARD_COLOR: '#8cdbfa',
} as StyledPropsTheme;

import { StyledF } from "@common/3rd";
import "@fortawesome/fontawesome-free/css/all.css";
import { GlobalStyledProps, StyledPropsTheme } from "@my-types";
import ChinaRed from "./themes/china-red";
import Orange from "./themes/orange";
import PortlandRed from "./themes/portland-red";
import Standard from "./themes/standard";
import Green from "./themes/green";
import "./antd-theme.less";
import "../iconfont/iconfont.css";
import CmiImage from "../assets/cmi.svg";

type THEMES_TYPE = {
  [propName: string]: StyledPropsTheme;
};
const themeMap: { [key: string]: StyledPropsTheme } = {
  ChinaRed: ChinaRed,
  Orange: Orange,
  Green: Green,
};
const THEMES = {
  "CHINA-RED": ChinaRed,
  "PORTLAND-RED": PortlandRed,
  ORANGE: Orange,
  CHINA: ChinaRed,
  PORTLAND: PortlandRed,
  TIGER: Orange,
  OLYMPICS: Standard,
} as THEMES_TYPE;

class Desktop {
  static ONLY =
    "(min-width: 900px) and (orientation: portrait), (min-width: 1200px) and (orientation: landscape)";

  static only(first: any, ...rest: any[]) {
    return StyledF.css`
			@media ${Desktop.ONLY} {
				${StyledF.css(first, ...rest)}
			}
		`;
  }
}

class AllMedias {
  static desk() {
    return Desktop;
  }
}

const resetStyle = `
*{outline:none!important;}
`;

class Styles {

  getTheme(name?: string): StyledPropsTheme {
    if (name) {
      return THEMES[name.toUpperCase().replace(/_/g, "-")] || Standard;
    } else {
      return !!process.env.REACT_APP_THEME ? themeMap[process.env.REACT_APP_THEME] : Standard;
    }
  }

  createGlobalStyles(name: string): React.ComponentClass<any> {
    const define = this.getTheme(name);

    return StyledF.createGlobalStyle`
			*,
			*:after,
			*:before {
				box-sizing: border-box;
			}
      * {
        padding: 0;
        margin: 0;
      } 
      :focus {
         outline: transparent !important;
      }
			html {
				font-size: ${define.FONT_SIZE_HTML}px;
				width: 100%;
				min-height: 100%;
				-webkit-text-size-adjust: 100%;
				-webkit-tap-highlight-color: transparent;
				--antd-wave-shadow-color: ${define.COLOR_PRIMARY};
        .grecaptcha-badge {
          display: none !important;
        }
				.ant-modal {
				  .ant-modal-title {
				    text-transform : uppercase
				  }
				}
			}
      :focus {
         outline: transparent !important;
      }
      .portrait90 {
        transform:rotate(90deg);
      }
      .portraitF90 {
        transform:rotate(-90deg);
      }
      .portrait180 {
        transform:rotate(180deg);
      }
			body {
				position: relative;
				width: 100%;
				margin: 0;
				padding: 0;
				color: ${define.FONT_COLOR};
				background: ${define.COLOR_BACKGROUND};
				font-family: ${define.FONT_FAMILY};
				font-weight: ${define.FONT_WEIGHT};
				font-size: ${define.FONT_SIZE}px;
				line-height: ${define.INPUT_HEIGHT}px;
				overflow-x: hidden;
				.mobile-plan {
				  .ant-drawer-flex-container {
				    display: block;
				  }
				}
				.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
          background: #323232;
          .ant-menu-item-selected {
            background-color: ${define.COLOR_PRIMARY};
          }
        }
				.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child
				, .ant-checkbox-checked::after{
				  border-color: ${define.COLOR_A};
				}
				.ant-upload.ant-upload-select-picture-card:hover {
				  border-color: ${define.COLOR_PRIMARY};
				}
				.ant-popover-inner-content {
				  // padding: 0;
				}
				.share-btn {
				  list-style: none;
				  li {
				    padding: 6px 20px;
            cursor: pointer;
            &:hover {
              background-color: ${define.COLOR_PRIMARY};
              color: #fff;
            }
            &:first-child {
              padding-top: 10px;
            }
            &:last-child {
              padding-bottom: 10px;
            }
            i {
              padding-right: 5px;
            }
				  }
				}
				.modal-new-quote {
				  .ant-modal-header {
				    padding: 40px 0 0 0;
				    border-bottom: 0;
				    border-radius: 20px 20px 0 0;
				  }
				  .ant-modal-title {
				    font-size: 20px;
            text-align: center;
            text-transform: uppercase;
            color: rgba(0,0,0,0.85);
            font-weight: bold;
            padding-top: 14px;
				  }
				  .ant-modal-content {
				    border-radius: 20px;
				  }
				  .ant-modal-body {
				    padding: 24px 40px;
				  }
				}
				#root {
				  height: 100%;
				  overflow-y: scroll;
				   -webkit-overflow-scrolling: touch; 
				}
      .ant-switch-checked {
        background-color: ${define.BORDER_COLOR};
      }
      .file-name-tip {
         .ant-tooltip-arrow {
            border-bottom-color: rgb(0,0,0,0.25);
         }
         .ant-tooltip-inner {
            background-color: rgb(0,0,0,0.25);
          }
      }
      .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a {
        color: #878787;
      }
      .ant-pagination-item-active {
        border-color: ${define.BORDER_COLOR};
        a {
          color: ${define.COLOR_PRIMARY};
        }
      }
      .ant-pagination-item:focus, .ant-pagination-item:hover {
        border-color: ${define.BORDER_COLOR};
        a {
          color: ${define.COLOR_PRIMARY};
        }
      }
       .ant-pagination-next:hover .ant-pagination-item-link {
          color: ${define.COLOR_PRIMARY};
          border-color: ${define.BORDER_COLOR};
       }
      .prod-type-image {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: 1em;
        vertical-align: middle;
        &::before {
          display: inline-block;
          width: 100%;
          height: 100%;
          content: '';
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
        &[data-type=CMI] {
          &::before {
            background-image: ${`url('${CmiImage}')`};
            }
          }
        }
        .price-card {
          position: absolute;
          margin-top: -26px;
          left: calc(50% - 35px);
        }
        .ant-dropdown-menu-item 
        {
          font-family: "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, Raleway, "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
          > a {
            color: #878787;
          }
          &:hover {
            > a {
              color: #fff;
            }
          }
        }
        .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover,
        .ant-select-dropdown-menu-item:hover, .ant-select-dropdown-menu-item-active {
          background-color: ${define.COLOR_PRIMARY};
          color: #fff;
          &:not(.ant-select-dropdown-menu-item-disabled) {
            background-color: ${define.COLOR_PRIMARY};
          }
        }
        .ant-input:focus, .ant-select-focused .ant-select-selection, .ant-select-selection:focus, 
        .ant-select-selection:active, .ant-input-number:focus {
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
        }
       .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
          border-color: ${define.BORDER_COLOR}
       }
        .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
          border-color: ${define.UPLOAD_BOARD_COLOR};
        }
        .ant-timeline-item-head-blue {
          color: ${define.BORDER_COLOR};
          border-color: ${define.BORDER_COLOR}
        }
        .ant-select-dropdown-menu-item-disabled:hover {
          background-color: #fff;
        }
        .header-menu {
          .ant-dropdown-menu {
            background-color: #323232; 
            border-radius: 0 0 4px 4px;
            .ant-dropdown-menu-item {
              color: #878787;
              &:hover {
                color: #fff;
              }
            }
             .ant-dropdown-menu-item-divider {
                background-color: #323232; 
             }
          }
        }
        .ant-drawer-body {
          background-color: #2f3639;
          padding: 0;
        }
        .ant-input:hover, .ant-input:focus,.ant-select-focused .ant-select-selection, 
        .ant-select-selection:focus, .ant-select-selection:active, .ant-select-selection:hover,
        .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled),
        .ant-checkbox-wrapper:hover .ant-checkbox-inner, 
        .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner,
        .ant-input-number:hover,.ant-input-number:focus{
          border-color: ${define.BORDER_COLOR};
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: ${define.COLOR_BACKGROUND_BTN};
          border-color: ${define.BORDER_COLOR};
        }
        .ant-btn-primary {
            color: #fff;
            background-color: ${define.COLOR_BACKGROUND_BTN};
            border-color: ${define.BORDER_COLOR};
        }
				.ant-btn:hover, .ant-btn:focus {
				  color: ${define.BORDER_COLOR};
                  border-color: ${define.BORDER_COLOR};
				}
				.ant-btn-primary:hover, .ant-btn-primary:focus {
				  color: #fff;
				  background-color: ${define.COLOR_BACKGROUND_BTN};
                  border-color: ${define.BORDER_COLOR};
				}
				.ant-btn-background-ghost.ant-btn-primary {
				   color:${define.BORDER_COLOR};
                   border-color: ${define.BORDER_COLOR};
				}
				.ant-btn-background-ghost.ant-btn-primary:hover, .ant-btn-background-ghost.ant-btn-primary:focus {
				  color:${define.BORDER_COLOR};
                  border-color: ${define.BORDER_COLOR};
				}
				a, a:hover {
				  color: ${define.COLOR_A};
				}
                .ant-input-lg {
                    font-weight: unset;
                }
                .ant-select, .ant-input {
                  color: #333 !important;
                }
                .ant-spin-dot-item {
                  background-color: ${define.COLOR_PRIMARY}
                }
                .ant-form-item-label > label {
                  color: #9e9e9e;
                }
                .container {
                     width: 1200px;
                     margin: 0 auto;
                     padding: 40px 0;
                     &.container-min-height: {
                        min-height: 78vh;
                     }
                }
                .head-container {
                  width: 1200px;
                  margin: 0 auto;
                }
                .containerMinHeight: {
                  min-height: 78vh !important;
                }
               
                 @media screen and (max-width: 480px)
                {
                    .container, .head-container {
                      width: 100%;
                    }
                }
                 @media screen and (max-width: 575px)
                {
                    .ant-form-item-label {
                      padding: 0;
                    }
                    .ant-form-item {
                      margin-bottom: 10px;
                    }
                }
                 @media screen and (min-width: 576px) and (max-width: 767px)
                {
                    .container, .head-container {
                      width: 100%;
                    }
                }
                .mobile-new-quote{
                    max-width: calc(100vw);
                    margin: 0 auto;
                    padding-bottom: 0;
                    .ant-modal-close {
                      margin-top: 10px;
                    }
                  
                   .ant-modal-header {
                      padding: 20px 0 0 0;
                      border-bottom: 0;
                      border-radius: 0;
                   }
                   .ant-modal-title {
                      text-align: center;
                      font-size: 24px;
                      line-height: 40px;
                   }
                   .ant-modal-content {
                      border-radius: 0;
                   }
                   .ant-modal-body {
                      padding: 24px;
                      -webkit-overflow-scrolling: touch; 
                      overflow-y: scroll;
                   }
                }
                .mobile-drawer {
                  .ant-drawer-body {
                    background-color: #fff;
                  }
                  .ant-drawer-content-wrapper {
                    width: 60% !important;
                  }
                  dl dd{
                    padding: 10px 0;
                    &.ant-form-item {
                      margin-bottom: 10px;
                    }
                    .ant-checkbox-group-item {
                      line-height: 30px;
                    }
                  }
                }
                .mobile-info {
                  .ant-modal-content {
                    width: 90% !important;
                    margin: 0 auto;
                  }
                }
                 @media screen and (min-width: 768px)
                {
                    .container, .head-container {
                      // width: 100%;
                       padding: 0 20px;
                    }
                }
              
                @media screen and (min-width: 992px)
                {
                    .container, .head-container {
                      // width: 992px;
                        padding: 0 20px;
                    }
                }
                @media screen and (min-width: 1200px) {
                    .container, .head-container {
                      // width: 1140px;
                       padding: 0 20px;
                    }
                }
                @media screen and (min-width: 1400px){
                    .container {
                      width: 1200px;
                       padding: 0;
                      &.container-min-height: {
                        min-height: 78vh;
                      }
                    }
                    .head-container {
                       width: 1200px;
                        padding: 0;
                    }
                }
                @media screen and (min-width: 1600px){
                    .container {
                      width: 1400px;
                      &.container-min-height: {
                        min-height: 78vh;
                      }
                    }
                    .head-container {
                       width: 1400px;
                    }
                }
				.ant-drawer-flex-container {
				      display: -ms-flexbox;
                      display: flex;
                      -ms-flex-direction: row;
                      flex-direction: row;
                      -ms-flex-pack: distribute;
                      justify-content: space-around;
                      -ms-flex-align: center;
                      align-items: center;
                      .ant-drawer-flex-contants-row-1 {
                          text-align-last: right;
                      }
                      .ant-drawer-flex-item {
                            width: 220px;
                            height: 110px;
                            -ms-flex-item-align: center;
                            align-self: center;
                            display: -ms-flexbox;
                            display: flex;
                            -ms-flex-direction: cloumn;
                            flex-direction: cloumn;
                            .ant-card-body {
                                  padding: 10px 15px 15px;
                            }
                            .premium {
                              strong {
                                font-size: 18px;
                                color: ${define.BACKGROUND_COLOR};
                                padding-right: 5px;
                                label {
                                  font-size: 80%;
                                  font-weight: 400;
                                  display: inline-block;
                                  content: attr(data-prefix);
                                  margin-right: .2em;
                                  vertical-align: bottom;
                                }
                              }
                              .price::before {
                                font-size: 80%;
                                font-weight: 400;
                                margin-right: .2em;
                                content: attr(data-prefix);
                                vertical-align: 0;
                              }
                              .price::after {
                                font-size: 80%;
                                font-weight: 400;
                                display: inline-block;
                                margin-left: .3em;
                                content: attr(data-postfix);
                                vertical-align: 1px;
                                color: #878787;
                              }
                            }
                      }
				}
			}
            .mobile-plan {
              .ant-drawer-flex-container {
                .ant-drawer-flex-item {
                  width: 100%;
                  margin-bottom: 10px;
                }
              }
            }
			h1, h2, h3, h4, h5, h6, p, pre {
				margin: 0;
			}

			input {
				&:-internal-autofill-selected,
				&:-internal-autofill-previewed,
				&:-webkit-autofill,
				&:-webkit-autofill:active,
				&:-webkit-autofill:focus,
				&:-webkit-autofill:hover {
					transition-delay: 999999s;
					transition: color 999999s ease-out, background-color 999999s ease-out;
				}
			}
			
			#root {
				display: block;
				position: relative;
				${resetStyle}
      }
		`;
  }
}

export default new Styles();
export { AllMedias, Desktop };
export const DEFAULT_THEME_NAME = "olympics";
export const asStandardThemeName = (name: string) => {
  switch (name.toLowerCase()) {
    case "china-red":
    case "china":
      return "china";
    case "portland-red":
    case "portland":
      return "portland";
    case "orange":
    case "tiger":
      return "tiger";
    case "green":
    default:
      return "olympics";
  }
};

import _ from "lodash";
import { AjaxHandler, AjaxHeaders, AjaxOptions, AjaxResponse } from "@my-types";
import { React } from "@common/3rd";
import Consts from "./consts";
import Utils from "./utils";
import Envs from "./envs";
import Language from "./language";
import { notification } from "antd";
import { getClientInfo } from "@common/ajax-before";
import { writeSyslog } from "@common/sys-log";
import spin from "../app/desk/component/spin";
import SessionLogin from "@desk-component/session-login";

class Ajax {
  private static buildResponseHeaders = (response: Response): AjaxHeaders => {
    const headers: AjaxHeaders = {};
    response.headers.forEach((value: string, key: string) => {
      headers[key] = value;
    });
    return headers;
  };

  private static buildResponseData = (response: Response, body: any): AjaxResponse => {
    return {
      headers: Ajax.buildResponseHeaders(response),
      body: body,
      status: response.status,
      statusText: response.statusText,
    };
  };

  private static handleResponse = (
    response: Response,
    responseType: string | null,
    successHandler: AjaxHandler,
    failHandler: AjaxHandler,
    options?: any,
  ): void => {
    const contentType = response.headers.get("content-type") || "";
    const isLoading = options && options.loading;
    if (contentType.includes("json")) {
      responseType = "json";
    } else if (contentType.includes("xml") || contentType.includes("text") || contentType.includes("html")) {
      responseType = "text";
    }

    let extractor = null;
    switch (responseType) {
      case "text":
        extractor = Response.prototype.text;
        break;
      case "blob":
        extractor = Response.prototype.blob;
        break;
      default:
        // json
        extractor = Response.prototype.json;
        break;
    }
    extractor
      .call(response)
      .then(data => {
        if (!["text", "blob"].includes(responseType!)) {
          const { respCode, respMessage } = data;
          if (respCode === "0000") {
            successHandler(Ajax.buildResponseData(response, data));
            if (isLoading) {
              spin.spinClose();
            }
          } else {
            notification.error({
              message: respCode,
              description: respMessage,
            });
            failHandler({
              headers: {},
              body: data,
              status: 200,
              statusText: "System error occurred.",
            });
            if (isLoading) {
              spin.spinClose();
            }
          }
        } else {
          successHandler(Ajax.buildResponseData(response, data));
          if (isLoading) {
            spin.spinClose();
          }
        }
      })
      .catch(ex => {
        Ajax.rejectWithError(ex, failHandler);
        if (isLoading) {
          spin.spinClose();
        }
      });
  };

  private static rejectWithError = (error: Error, reject: AjaxHandler): void => {
    reject({
      headers: {},
      body: {
        respCode: Consts.FETCH_ERROR,
        respMessage: `${error.name}: ${error.message}`,
        respData: null,
      },
      status: 0,
      statusText: "Fetch error occurred.",
      // keep original error here
      error: error,
    });
  };

  all<T>(promises: (T | PromiseLike<T>)[]): Promise<T[]> {
    return Promise.all(promises);
  }

  put(url: string, data?: any, options?: AjaxOptions): Promise<AjaxResponse> {
    return this.ajax(url, data, Object.assign({}, options, { method: "PUT" }));
  }

  post(url: string, data?: any, options?: AjaxOptions): Promise<AjaxResponse> {
    return this.ajax(url, data, Object.assign({}, options, { method: "POST" }));
  }

  patch(url: string, data?: any, options?: AjaxOptions): Promise<AjaxResponse> {
    return this.ajax(url, data, Object.assign({}, options, { method: "PATCH" }));
  }

  delete(url: string, data?: any, options?: AjaxOptions): Promise<AjaxResponse> {
    return this.ajax(url, data, Object.assign({}, options, { method: "DELETE" }));
  }

  get(url: string, data?: any, options?: AjaxOptions): Promise<AjaxResponse> {
    return this.ajax(url, data, Object.assign({}, options, { method: "GET" }));
  }

  ajax(url: string, data: any, options: AjaxOptions): Promise<AjaxResponse> {
    let headers = options.headers || {};
    let isFileUpload: boolean = false;
    if (headers["Content-Type"] == null) {
      headers["Content-Type"] = "application/json";
    } else if (headers["Content-Type"] === false) {
      delete headers["Content-Type"];
      isFileUpload = true;
    }
    headers = {
      ...headers,
      ...getClientInfo(isFileUpload),
    };
    this.appendAuth(headers, options.ignoreAuth);
    const opts = {
      method: (options.method || "GET").toUpperCase(),
      headers: headers,
      credentials: options.credentials || "same-origin",
      mode: options.mode || "cors",
      redirect: options.redirect || "follow",
      cache: options.cache || "default",
      body: "" as any,
    };

    const responseType = options.dataType || "json";
    if (opts.method === "GET") {
      if (data) {
        url += `?${this.generateQueryString(data)}`;
      }
      delete opts.body;
    } else if (Utils.isFormData(data)) {
      opts.body = data;
    } else {
      opts.body = JSON.stringify(data || {});
    }
    const isLoading = options && options.loading;
    if (isLoading) {
      spin.spinShow();
    }
    return new Promise((resolve, reject) => {
      fetch(this.appendLang(this.getServiceLocation(url)), opts as any)
        .then((response: Response) => {
          if (response.status === 401) {
            SessionLogin.showConfirm();
            //10秒之后自动跳入登录页面
            setTimeout(() => {
              SessionLogin.routerSign();
            }, 10000);
          }
          if (response.ok) {
            Ajax.handleResponse(response, responseType, resolve, reject, options);
          } else {
            Ajax.handleResponse(response, responseType, reject, reject, options);
          }
        })
        .catch((e: Error) => {
          if (url.includes("/syslogs/write")) {
            //todo
            writeSyslog({
              traceId: _.get(opts, "headers.x-insmate-traceid"),
              logType: "Ajax Log",
              logLevel: "INFO",
              message: _.toString(e),
            });
          }
          Ajax.rejectWithError(e, reject);
        });
    });
  }

  private generateQueryString(obj: any): string {
    return Utils.toQueryString(obj);
  }

  getServiceLocation(relativePath?: string | null): string {
    if (relativePath && (relativePath.startsWith("https://") || relativePath.startsWith("http://"))) {
      return relativePath;
    }
    let url = window.location;
    let port = url.port;
    if (process.env.REACT_APP_AJAX_SERVER_PORT) {
      port = `:${process.env.REACT_APP_AJAX_SERVER_PORT}`;
    } else if (port) {
      port = `:${port}`;
    }
    let hostname = url.hostname;
    if (process.env.REACT_APP_AJAX_SERVER_HOST) {
      hostname = process.env.REACT_APP_AJAX_SERVER_HOST;
    }
    let context = process.env.REACT_APP_AJAX_SERVER_CONTEXT || "";
    // let location = `${url.protocol}//${hostname}${port}${context}`;
    let location = `${hostname}${port}${context}`;
    if (!location.startsWith("http://") && !location.startsWith("https://")) {
      location = `${url.protocol}//${hostname}${port}${context}`;
    }

    if (relativePath) {
      return location + relativePath;
    } else {
      return location;
    }
  }

  private appendLang(url: string): string {
    if (!url) {
      return url;
    }
    const joiner = url.indexOf("?") === -1 ? "?" : "&";
    return `${url}${joiner}lang=${Language.language}`;
  }

  private appendAuth(headers: AjaxHeaders, ignoreAuth: boolean = false): void {
    if (ignoreAuth === true) {
      return;
    }
    if (!headers[Consts.AUTH_KEY]) {
      headers[Consts.AUTH_KEY] = Envs.getAuth() || "";
    }
  }

  appendAuthToUrl(url: string) {
    const apiUrl = this.getServiceLocation(url);
    const accessKey = Envs.getAuth();
    const separator = apiUrl.indexOf("?") === -1 ? "?" : "&";
    return `${apiUrl}${separator}access_token=${encodeURIComponent(accessKey)}`;
  }
}

export default new Ajax();


import moment, { Moment, MomentInput } from "moment";

import Consts from "../consts";


class DateUtils {
  now() {
    if (this.getTimeZone()) return moment().utcOffset(this.getTimeZone());
    return moment();
  }

  formatNowWithTimeZone() {
    return this.now().format(Consts.DATE_FORMAT.DATE_TIME_WITH_TIME_ZONE);
  }

  formatDateTimeWithTimeZone(moment: Moment) {
    // todo 判断moment类型
    return moment.format(Consts.DATE_FORMAT.DATE_TIME_WITH_TIME_ZONE);
  }

  /**
   * format date to DD/MM/YYYY
   * @param moment
   */
  formatDate(moment: Moment) {
    // todo 判断moment类型
    return moment.format(Consts.DATE_FORMAT.DATE_FORMAT);
  }

  formatDateTimeZone(moment: Moment) {
    // todo 判断moment类型
    return moment.format(Consts.DATE_FORMAT.DATE_TIME_WITH_TIME_ZONE);
  }

  formatDateTime(moment: Moment) {
    // todo 判断moment类型
    return moment.format(Consts.DATE_FORMAT.DATE_TIME_FORMAT);
  }

  nowWithTimeAtStartOfDay() {
    return this.now().startOf("day");
  }

  nowWithTimeAtStartOfMonth() {
    return this.now().startOf("month");
  }

  nowWithTimeAtStartOfYear() {
    return this.now().startOf("year");
  }

  nowWithTimeAtEndOfYear() {
    return this.now().endOf("year");
  }

  nowWithTimeAtEndOfMonth() {
    return this.now().endOf("month");
  }

  plusXDaysFromNow(days: number) {
    return this.now().add(days, "days");
  }

  plusXWeeksFromNow(weeks: number) {
    return this.now().add(weeks, "weeks");
  }

  plusXMonthsFromNow(months: number) {
    return this.now().add(months, "months");
  }

  plusXYearsFromNow(years: number) {
    return this.now().add(years, "years");
  }

  minusXMonthsFromNow(months: number) {
    return this.now().subtract(months, "months");
  }

  minusXMonthsFromNowMinus1Day(months: number) {
    return this.now().subtract(months, "months").add(1, "days");
  }

  minusXDaysFromNow(days: number) {
    return this.now().subtract(days, "days");
  }

  minusXHoursFromNow(hours: number) {
    return this.now().subtract(hours, "hours");
  }

  minusXWeeksFromNowMinus1Day(weeks: number) {
    return this.now().subtract(weeks, "weeks").add(1, "days");
  }

  minusXWeeksFromNow(weeks: number) {
    return this.now().subtract(weeks, "weeks");
  }

  minusXYearsFromNow(years: number) {
    return this.now().subtract(years, "years");
  }

  minusXYearsFromNowMinus1Day(years: number) {
    return this.now().subtract(years, "years").add(1, "days");
  }

  toDate(dateStringWithTimeZone: string | Moment) {
    return moment(dateStringWithTimeZone, Consts.DATE_FORMAT.DATE_TIME_WITH_TIME_ZONE).utcOffset(this.getTimeZone());
  }

  private getTimeZone() {
    const currentZone = (0 - new Date().getTimezoneOffset() / 60);
    return currentZone;
  }
}

export default new DateUtils();
